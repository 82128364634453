import { ApiException } from '@/core';
import { NotiApiError } from './enums';

export class NotiApiException extends ApiException {
  get message(): string {
    let message = this.name;

    switch (this.name) {
      case NotiApiError.NotFoundNotiSound:
        message = '알림음을 찾을 수 없습니다.';
        break;

      case NotiApiError.NotFoundNotiSoundCategory:
        message = '알림음 카테고리를 찾을 수 없습니다.';
        break;

      case NotiApiError.CannotUpdateDefaultNotiSound:
        message = '기본 설정은 일반/조건 중 하나여야만 합니다.';
        break;

      case NotiApiError.CannotDeleteDefaultNotiSound:
        message = '기본 알림음을 삭제할 수 없습니다.';
        break;
    }

    return message;
  }

  get statusMessage() {
    let message = this.name;

    switch (this.name) {
      case NotiApiError.NotFoundNotiSound:
        message = '알림음을 찾을 수 없습니다.';
        break;

      case NotiApiError.CannotUpdateDefaultNotiSound:
        message = '기본 알림음은 항상 공개 상태여야 합니다.';
        break;
    }

    return message;
  }
}
