export enum TtsProvider {
  Google = 'google',
}

export enum TtsStatus {
  Active = 'active',
  Disable = 'disable',
}

export enum TtsApiError {
  InvalidTtsProvider = 'InvalidTtsProvider',
}

export enum TtsVoiceApiError {
  NotFoundTtsVoice = 'NotFoundTtsVoice',
  AlreadyExistTtsVoice = 'AlreadyExistTtsVoice',
  AlreadyUsedTtsVoice = 'AlreadyUsedTtsVoice',
}
