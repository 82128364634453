import { DateTime } from 'luxon';
import { useCallback } from 'react';
import { CellProps } from 'react-table';

import { AwsS3Bucket, ISOToString, ListQueryStore, LuxonFormat, NumberToDollar, SortOrder, config } from '@/core';
import { AccordionSelectOption, PaginationTableColumnType } from '@/components';
import {
  DonationApiService,
  DonationDto,
  DonationKeywordOption,
  DonationQueryDto,
  donationApiService,
  donationService,
} from '@/service';

import { DonationStoreValueType } from './types';

export class DonationStore extends ListQueryStore<DonationDto, DonationStoreValueType, DonationApiService> {
  useSetRows() {
    return super.useSetRows<DonationQueryDto>('offset', 'limit', 'startDate', 'endDate', 'sortOrder');
  }

  useSearchRows() {
    return super.useSetRows<DonationQueryDto>();
  }

  useKeywordOptions(): AccordionSelectOption[] {
    return [
      {
        label: 'All',
        value: undefined,
      },
      {
        label: 'Donation ID',
        value: DonationKeywordOption.DONATION_ID,
      },
      {
        label: 'Donation Nickname',
        value: DonationKeywordOption.DONATION_NICKNAME,
      },
      {
        label: 'Receiver ID',
        value: DonationKeywordOption.RECEIVER_ID,
      },
      {
        label: 'Receiver Nickname',
        value: DonationKeywordOption.RECEIVER_NICKNAME,
      },
      {
        label: 'Sender ID',
        value: DonationKeywordOption.SENDER_ID,
      },
      {
        label: 'Sender Nickname',
        value: DonationKeywordOption.SENDER_NICKNAME,
      },
    ];
  }

  useColumns(): PaginationTableColumnType<DonationDto>[] {
    return [
      {
        accessor: 'id',
        Header: 'ID',
        minWidth: 60,
        maxWidth: 60,
      },
      {
        id: 'receiver_id',
        Header: 'Receiver ID',
        minWidth: 60,
        maxWidth: 60,
        Cell: ({ row }: CellProps<DonationDto>) => <>{row.original.receiver.id}</>,
      },
      {
        accessor: 'receiver',
        Header: 'Receiver',
        Cell: ({ value }) => (
          <div
            style={{
              display: 'flex',
              minWidth: 80,
              alignItems: 'center',
              justifyContent: 'center',
              padding: '5px 0',
            }}
          >
            <div
              style={{
                height: 30,
                width: 30,
                margin: '0 10px 0 0',
                borderRadius: '50%',
                background: `url(${config.S3_URL(AwsS3Bucket.Profile, value.imagePath)})`,
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundPositionX: 'center',
              }}
            />
            <div>{value.nickname}</div>
          </div>
        ),
      },
      {
        id: 'sender_id',
        Header: 'Sender ID',
        minWidth: 60,
        maxWidth: 60,
        Cell: ({ row }: CellProps<DonationDto>) => <>{row.original.sender.id}</>,
      },
      {
        accessor: 'sender',
        Header: 'Sender',
        Cell: ({ value }) => {
          return (
            <div
              style={{
                display: 'flex',
                minWidth: 80,
                alignItems: 'center',
                justifyContent: 'center',
                padding: '5px 0',
              }}
            >
              <div
                style={{
                  height: 30,
                  width: 30,
                  margin: '0 10px 0 0',
                  borderRadius: '50%',
                  background: `url(${config.S3_URL(AwsS3Bucket.Profile, value.imagePath)})`,
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundPositionX: 'center',
                }}
              />
              <div>{value.nickname}</div>
            </div>
          );
        },
      },
      {
        accessor: 'nickname',
        Header: 'Donation Nickname',
        minWidth: 60,
        Cell: ({ value }) => <>{value}</>,
      },
      {
        accessor: 'amount',
        Header: 'Price',
        minWidth: 60,
        Cell: ({ value }) => <>{NumberToDollar(value)}</>,
      },
      {
        id: 'type',
        Header: 'Templates',
        minWidth: 100,
        Cell: ({ row }: CellProps<DonationDto>) => <>{donationService.getTypes(row.original).join(', ') || '없음'}</>,
      },
      {
        accessor: 'isOffline',
        Header: 'Live Stream',
        minWidth: 20,
        Cell: ({ value }) => <>{value ? 'OFFLINE' : 'ONLINE'}</>,
      },
      {
        id: 'detail',
        Header: 'Details',
        width: 10,
        Cell: ({ row }: CellProps<DonationDto>) => {
          const types = donationService.getTypes(row.original);

          const activeDetail = this.useActiveDetail();
          const onClickDetail = useCallback(() => activeDetail(row.original.id), [activeDetail, row.original.id]);

          if (types.length === 0) {
            return <>-</>;
          }

          return (
            <button onClick={onClickDetail}>
              <i className="bx fs-4 bx-link-external custom-icon" />
            </button>
          );
        },
      },
      {
        accessor: 'createdAt',
        Header: 'Datetime',
        minWidth: 130,
        Cell: ({ value }) => {
          if (!value) {
            return <>-</>;
          }

          return <>{ISOToString(value, LuxonFormat.Minute)}</>;
        },
      },
    ];
  }
}

export const donationStore = new DonationStore(
  {
    amounts: 0,
    total: 0,
    rows: [],
    query: {
      offset: 0,
      limit: 10,
      sortOrder: SortOrder.DESC,
      startDate: DateTime.local().startOf('month').toFormat(LuxonFormat.Day),
      endDate: DateTime.local().endOf('month').toFormat(LuxonFormat.Day),
    },
  },
  donationApiService,
);
