import { ApiException } from '@/core';
import { StaffApiError } from './enums';

export class StaffApiException extends ApiException {
  get message() {
    let message = this.name;

    switch (this.name) {
      case StaffApiError.AlreadyExistStaff:
        message = '이미 존재하는 계정입니다.';
        break;

      case StaffApiError.NotFoundStaff:
        message = '계정을 찾을 수 없습니다.';
        break;

      case StaffApiError.NotFoundRole:
        message = '역할을 찾을 수 없습니다.';
        break;
    }

    return message;
  }
}
