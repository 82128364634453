import ReactSelect from 'react-select';
import { ContentModalSelectOptionType, ContentModalSelectProps } from './types';

export function ContentModalSingleSelect<D extends object>({
  label,
  origins,
  value,
  labelKey,
  valueKey,
  onChange,
  placeholder,
  noOptionText,
}: ContentModalSelectProps<D>) {
  const options = origins.map((origin) => ({
    label: origin[labelKey],
    value: origin[valueKey],
  })) as ContentModalSelectOptionType[];

  const target = value ? origins.find((origin) => origin[valueKey] === value) : null;
  const option = target ? ({ label: target[labelKey], value: target[valueKey] } as ContentModalSelectOptionType) : null;

  return (
    <div className="form-group mb-4">
      {label && <label>{label}</label>}
      <ReactSelect
        isMulti={false}
        name={valueKey.toString()}
        placeholder={placeholder}
        noOptionsMessage={noOptionText ? () => noOptionText : undefined}
        classNamePrefix="select2-selection"
        options={options}
        value={option}
        onChange={onChange}
      />
    </div>
  );
}
