import { DateTime } from 'luxon';
import { MouseEventHandler, useCallback, useState } from 'react';
import { CellProps } from 'react-table';

import { ISOToString, ListDialogStore, LuxonFormat } from '@/core';
import { SimpleTableColumnType, rootAlertStore } from '@/components';
import {
  CreateMaintenanceBodyDto,
  MaintenanceApiService,
  MaintenanceDto,
  MaintenanceStatus,
  UpdateMaintenanceBodyDto,
  maintenanceApiService,
} from '@/service';

import { MaintenanceStoreValueType } from './types';

export class MaintenanceStore extends ListDialogStore<
  MaintenanceDto,
  MaintenanceStoreValueType,
  MaintenanceApiService
> {
  private useColumnHandlers() {
    const activeStart = this.useActiveStart();
    const activeEnd = this.useActiveEnd();
    const activeUpdate = this.useActiveUpdate();
    const activeDelete = this.useActiveDelete();
    const activeDetailDto = this.useActiveDetailDto();

    const onClickStartHandler = useCallback(
      (id: number): MouseEventHandler<HTMLButtonElement> => {
        return () => activeStart(id);
      },
      [activeStart],
    );

    const onClickEndHandler = useCallback(
      (id: number): MouseEventHandler<HTMLButtonElement> => {
        return () => activeEnd(id);
      },
      [activeEnd],
    );

    const onClickUpdateHandler = useCallback(
      (id: number): MouseEventHandler<HTMLButtonElement> => {
        return () => activeUpdate(id);
      },
      [activeUpdate],
    );

    const onClickDeleteHandler = useCallback(
      (id: number): MouseEventHandler<HTMLButtonElement> => {
        return () => activeDelete(id);
      },
      [activeDelete],
    );

    const onClickDetailHandler = useCallback(
      (row: MaintenanceDto) => {
        return () => activeDetailDto(row);
      },
      [activeDetailDto],
    );

    return { onClickStartHandler, onClickEndHandler, onClickUpdateHandler, onClickDeleteHandler, onClickDetailHandler };
  }

  useColumns(): SimpleTableColumnType<MaintenanceDto>[] {
    const current = this.useCurrent();

    const { onClickStartHandler, onClickEndHandler, onClickUpdateHandler, onClickDeleteHandler, onClickDetailHandler } =
      this.useColumnHandlers();

    return [
      {
        accessor: 'id',
        Header: 'ID',
        minWidth: 40,
        maxWidth: 40,
      },
      {
        id: 'details',
        Header: '',
        minWidth: 20,
        maxWidth: 20,
        Cell: ({ row }: CellProps<MaintenanceDto>) => {
          return (
            <button onClick={onClickDetailHandler(row.original)}>
              <i className="bx fs-4 bx-detail custom-icon" />
            </button>
          );
        },
      },
      {
        accessor: 'minutes',
        Header: '예상소요시간',
        minWidth: 80,
        maxWidth: 80,
        Cell: ({ value }) => <>{`${value}분`}</>,
      },
      {
        accessor: 'createdAt',
        Header: '등록일시',
        minWidth: 120,
        maxWidth: 120,
        Cell: ({ value }) => <>{value ? ISOToString(value, LuxonFormat.Second) : '-'}</>,
      },
      {
        accessor: 'staff',
        Header: '게시자',
        minWidth: 120,
        maxWidth: 120,
        Cell: ({ value }) => <>{value ? value.name : '-'}</>,
      },
      {
        id: 'button',
        minWidth: 100,
        maxWidth: 100,
        Cell: ({ row }: CellProps<MaintenanceDto>) => {
          const waiting = row.original.status === MaintenanceStatus.Wait;
          const className = waiting ? (current ? 'btn-outline-secondary' : 'btn-outline-info') : 'btn-outline-warning';
          const onClickEvent = waiting ? onClickStartHandler(row.original.id) : onClickEndHandler(row.original.id);

          const [text, setText] = useState<string>(waiting ? '대기중' : '점검중');

          const onButtonOver = useCallback(() => {
            if (waiting) {
              setText('점검 시작');
            } else {
              setText('점검 종료');
            }
          }, [waiting, setText]);

          const onButtonLeave = useCallback(() => {
            if (waiting) {
              setText('대기중');
            } else {
              setText('점검중');
            }
          }, [waiting, setText]);

          return (
            <button
              disabled={!!waiting && !!current}
              className={['btn', className].join(' ')}
              onClick={onClickEvent}
              onMouseOver={onButtonOver}
              onMouseLeave={onButtonLeave}
            >
              {text}
            </button>
          );
        },
      },
      {
        id: 'update',
        minWidth: 10,
        maxWidth: 10,
        Cell: ({ row }: CellProps<MaintenanceDto>) => {
          return (
            <button onClick={onClickUpdateHandler(row.original.id)}>
              <i className="bx fs-4 bx-pencil custom-icon" />
            </button>
          );
        },
      },
      {
        id: 'delete',
        minWidth: 10,
        maxWidth: 10,
        Cell: ({ row }: CellProps<MaintenanceDto>) => {
          return (
            <button onClick={onClickDeleteHandler(row.original.id)}>
              <i className="bx fs-4 bx-trash text-danger custom-icon" />
            </button>
          );
        },
      },
    ];
  }

  useHistoryColumns(): SimpleTableColumnType<MaintenanceDto>[] {
    const { onClickDetailHandler } = this.useColumnHandlers();

    return [
      {
        accessor: 'id',
        Header: 'ID',
        minWidth: 40,
        maxWidth: 40,
      },
      {
        id: 'details',
        Header: '',
        minWidth: 20,
        maxWidth: 20,
        Cell: ({ row }: CellProps<MaintenanceDto>) => {
          return (
            <button onClick={onClickDetailHandler(row.original)}>
              <i className="bx fs-4 bx-detail custom-icon" />
            </button>
          );
        },
      },
      {
        accessor: 'startAt',
        Header: '시작일시',
        minWidth: 120,
        maxWidth: 120,
        Cell: ({ value }) => <>{value ? ISOToString(value, LuxonFormat.Second) : '-'}</>,
      },
      {
        accessor: 'endAt',
        Header: '종료일시',
        minWidth: 120,
        maxWidth: 120,
        Cell: ({ value }) => <>{value ? ISOToString(value, LuxonFormat.Second) : '-'}</>,
      },
      {
        id: 'real-minutes',
        Header: '실 소요 시간',
        minWidth: 80,
        maxWidth: 80,
        Cell: ({ row }: CellProps<MaintenanceDto>) => {
          const end = DateTime.fromISO(row.original.endAt);
          const start = DateTime.fromISO(row.original.startAt);
          const minutes = end.diff(start, 'minutes').get('minutes');

          return <>{minutes < 1 ? `${Math.floor(minutes * 60)}초` : `${Math.floor(minutes)}분`}</>;
        },
      },
      {
        accessor: 'staff',
        Header: '점검자',
        minWidth: 120,
        maxWidth: 120,
        Cell: ({ value }) => <>{value ? value.name : '-'}</>,
      },
    ];
  }

  useSetRows(): () => Promise<void> {
    const setState = this.useSetState();

    return useCallback(async () => {
      const rows = await this.service.getRows();

      const maps = rows.reduce<Pick<MaintenanceStoreValueType, 'rows' | 'histories' | 'current'>>(
        (prev, row) => {
          switch (row.status) {
            case MaintenanceStatus.Start:
            case MaintenanceStatus.Wait:
              if (row.status === MaintenanceStatus.Start) {
                prev.current = row;
              }

              prev.rows.push(row);
              break;

            case MaintenanceStatus.End:
              prev.histories.push(row);
              break;
          }

          return prev;
        },
        { rows: [], histories: [], current: null },
      );

      setState((prev) => ({ ...prev, ...maps }));
    }, [setState]);
  }

  useCurrent(): MaintenanceDto | null {
    return this.useValue().current;
  }

  useHistories(): MaintenanceDto[] {
    return this.useValue().histories;
  }

  useStartTarget(): number | undefined {
    return this.useValue().startId;
  }

  useActiveStart(): (id: number) => void {
    const setState = this.useSetState();

    return useCallback(
      (startId) => {
        setState((prev) => ({ ...prev, startId }));
      },
      [setState],
    );
  }

  useDisableStart(): () => void {
    const setState = this.useSetState();

    return useCallback(() => {
      setState((prev) => ({ ...prev, startId: undefined }));
    }, [setState]);
  }

  useEndTarget(): number | undefined {
    return this.useValue().endId;
  }

  useActiveEnd(): (id: number) => void {
    const setState = this.useSetState();

    return useCallback(
      (endId) => {
        setState((prev) => ({ ...prev, endId }));
      },
      [setState],
    );
  }

  useDisableEnd(): () => void {
    const setState = this.useSetState();

    return useCallback(() => {
      setState((prev) => ({ ...prev, endId: undefined }));
    }, [setState]);
  }

  useDetail(): MaintenanceDto | null {
    return this.useValue().detail;
  }

  useActiveDetailDto(): (detail: MaintenanceDto) => void {
    const setState = this.useSetState();

    return useCallback(
      (detail) => {
        setState((prev) => ({ ...prev, detail }));
      },
      [setState],
    );
  }

  useDisableDetail(): () => void {
    const setState = this.useSetState();

    return useCallback(() => {
      setState((prev) => ({ ...prev, detail: null }));
    }, [setState]);
  }

  useOnClickStartHandler(): () => Promise<void> {
    const startId = this.useValue().startId;
    const setRows = this.useSetRows();
    const disableStart = this.useDisableStart();

    return useCallback(async () => {
      if (!startId) {
        return;
      }

      await this.service.updateStatus(startId, MaintenanceStatus.Start, {
        async finalHandler() {
          await setRows();
          disableStart();
        },
      });
    }, [startId, setRows, disableStart]);
  }

  useOnClickEndHandler(): () => Promise<void> {
    const current = this.useCurrent();
    const setRows = this.useSetRows();
    const disableEnd = this.useDisableEnd();

    return useCallback(async () => {
      if (!current) {
        return;
      }

      await this.service.updateStatus(current.id, MaintenanceStatus.End, {
        async finalHandler() {
          await setRows();
          disableEnd();
        },
      });
    }, [current, setRows, disableEnd]);
  }

  useOnClickCreateHandler(body: CreateMaintenanceBodyDto): () => Promise<void> {
    const setAlert = rootAlertStore.useSetState();
    const setRows = this.useSetRows();
    const disableCreate = this.useDisableCreateMode();

    return useCallback(async () => {
      let message = '';

      if (!body.minutes) {
        message = '예상 소요 시간을 입력하세요.';
      } else if (!body.message) {
        message = '점검 내용을 입력하세요.';
      }

      if (message) {
        setAlert({ type: 'warning', message });
        return;
      }

      await this.service.create(body);
      await setRows();

      disableCreate();
    }, [body, setAlert, setRows, disableCreate]);
  }

  useOnClickUpdateHandler(id: number, body: UpdateMaintenanceBodyDto): () => Promise<void> {
    const setAlert = rootAlertStore.useSetState();
    const setRows = this.useSetRows();
    const disableUpdate = this.useDisableUpdate();

    return useCallback(async () => {
      if (!id) {
        return;
      }

      let message = '';

      if (!body.minutes) {
        message = '예상 소요 시간을 입력하세요.';
      } else if (!body.message) {
        message = '점검 내용을 입력하세요.';
      }

      if (message) {
        setAlert({ type: 'warning', message });
        return;
      }

      await this.service.update(id, body);
      await setRows();

      disableUpdate();
    }, [id, body, setAlert, setRows, disableUpdate]);
  }

  useOnClickDeleteHandler(): () => Promise<void> {
    const id = this.useDeleteTarget();
    const setRows = this.useSetRows();
    const disableDelete = this.useDisableDelete();

    return useCallback(async () => {
      if (!id) {
        return;
      }

      await this.service.delete(id, {
        async finalHandler() {
          await setRows();
          disableDelete();
        },
      });
    }, [id, setRows, disableDelete]);
  }
}

export const maintenanceStore = new MaintenanceStore(
  {
    rows: [],
    histories: [],
    current: null,
    detail: null,
    createMode: false,
    updateId: undefined,
    deleteId: undefined,
    startId: undefined,
  },
  maintenanceApiService,
);
