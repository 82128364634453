import { Fragment } from 'react';
import { PageHelmet } from './page.helmet';
import { PageProperty } from './types';

export const PageProvider = (property: PageProperty): PageProperty => {
  return {
    ...property,
    element: (
      <Fragment>
        <PageHelmet title={property.title} />
        {property.element}
      </Fragment>
    ),
  };
};
