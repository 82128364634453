import { AccordionRadioProps } from './types';

export function AccordionRadio({ id, label, ...props }: AccordionRadioProps) {
  return (
    <div className="form-check mb-4" style={{ marginRight: '10px' }}>
      <input className="form-check-input" type="radio" id={id} value={id} {...props} style={{ cursor: 'pointer' }} />
      <label className="form-check-label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
}
