export enum StickiesStatus {
  ACTIVE = 'active',
  DISABLED = 'disabled',
}

export enum StickiesEventStatus {
  ACTIVE = 'active',
  DISABLED = 'disabled',
}

export enum StickiesApiError {
  INVALID_GROSS_AMOUNT = 'InvalidStickiesGrossAmount',
  INVALID_DISCOUNT_AMOUNT = 'InvalidStickiesDiscountRate',
  ALREADY_EXIST_ITEM = 'AlreadyExistStickies',
}

export enum StickiesChargeApiError {
  NOT_FOUND_USER = 'NotFoundUser',
  INVALID_STICKIES_CHARGE_AMOUNT = 'InvalidStickiesChargeAmount',
}

export enum StickiesEventApiError {
  ALREADY_EXIST_STICKIES_EVENT = 'AlreadyExistStickiesEvent',
  INVALID_STICKIES_EVENT_AMOUNT = 'InvalidStickiesEventAmount',
  NOT_FOUND_STICKIES_EVENT = 'NotFoundStickiesEvent',
}
