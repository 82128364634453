import { UserOAuthPlatform, UserStatus } from './enums';

export class UserService {
  getTextByOAuthPlatform(platform: UserOAuthPlatform): string {
    let text = '';

    switch (platform) {
      case UserOAuthPlatform.TWITCH:
        text = 'Twitch';
        break;

      case UserOAuthPlatform.GOOGLE:
        text = 'Google';
        break;
    }

    return text;
  }

  getTextByStatus(status: UserStatus | undefined): string {
    let text = '';

    switch (status) {
      case UserStatus.ACTIVE:
        text = 'Active';
        break;

      case UserStatus.BLOCK:
        text = 'Blocked';
        break;

      case UserStatus.WITHDRAW:
        text = 'Withdrew';
        break;
    }

    return text;
  }

  getReverseStatus(status: UserStatus | undefined): UserStatus | undefined {
    switch (status) {
      case UserStatus.ACTIVE:
        return UserStatus.BLOCK;

      case UserStatus.BLOCK:
      case UserStatus.WITHDRAW:
        return UserStatus.ACTIVE;
    }
  }

  getClassNameByStatus(status: UserStatus): string {
    let className = '';

    switch (status) {
      case UserStatus.ACTIVE:
        className = 'btn-outline-info';
        break;

      case UserStatus.BLOCK:
        className = 'btn-outline-warning';
        break;

      case UserStatus.WITHDRAW:
        className = 'btn-outline-secondary';
        break;
    }

    return className;
  }
}

export const userService = new UserService();
