import { ApiException } from '@/core';
import { TtsApiError } from './enums';

export class TtsApiException extends ApiException {
  get message(): string {
    let message = this.name;

    switch (this.name) {
      case TtsApiError.InvalidTtsProvider:
        message = '지원하지 않는 제공사입니다.';
        break;
    }

    return message;
  }
}
