import { ApiService, ListApiService } from '@/core/service';
import { DragDropTableSequenceType } from '@/components';
import { CreateNotiBodyDto, NotiDto, UpdateNotiBodyDto } from './types';
import { NotiStatus, NotiType } from './enums';
import { NotiApiException } from './noti.exception';
import { ApiAlertConfig, ApiHandlerConfig } from '@/core';

export class NotiApiService extends ApiService implements ListApiService<NotiDto> {
  async getRows(categoryId: number, handlerConfig: ApiHandlerConfig): Promise<NotiDto[]> {
    const alertConfig: ApiAlertConfig = {
      errorAlert(e) {
        return new NotiApiException(e).message;
      },
    };

    return this.api.get(this.url('list', categoryId), { ...alertConfig, ...handlerConfig });
  }

  async getRow(id: number, handlerConfig: ApiHandlerConfig): Promise<NotiDto> {
    const alertConfig: ApiAlertConfig = {
      errorAlert(e) {
        return new NotiApiException(e).message;
      },
    };

    return this.api.get(this.url(id), { ...alertConfig, ...handlerConfig });
  }

  async create(body: CreateNotiBodyDto, onClose: () => void): Promise<void> {
    return this.api.post(this.url(), body, {
      successAlert() {
        onClose();
        return '알림음이 등록되었습니다.';
      },
      errorAlert(e) {
        return new NotiApiException(e).message;
      },
    });
  }

  async update(id: number, body: UpdateNotiBodyDto, onClose: () => void): Promise<void> {
    return this.api.patch(this.url(id), body, {
      successAlert() {
        onClose();
        return '알림음이 수정되었습니다.';
      },
      errorAlert(e) {
        return new NotiApiException(e).message;
      },
    });
  }

  async updateType(id: number, type: NotiType, handlerConfig: ApiHandlerConfig): Promise<void> {
    const alertConfig: ApiAlertConfig = {
      successAlert() {
        return '기본 알림음이 변경되었습니다.';
      },
      errorAlert(e) {
        return new NotiApiException(e).message;
      },
    };

    return this.api.patch(this.url(id, 'type'), { type }, { ...alertConfig, ...handlerConfig });
  }

  async updateStatus(id: number, status: NotiStatus, handlerConfig: ApiHandlerConfig): Promise<void> {
    const alertConfig: ApiAlertConfig = {
      successAlert() {
        return '알림음 상태가 변경되었습니다.';
      },
      errorAlert(e) {
        return new NotiApiException(e).statusMessage;
      },
    };

    return this.api.patch(this.url(id), { status }, { ...alertConfig, ...handlerConfig });
  }

  async updateSequence(rows: DragDropTableSequenceType[], handlerConfig: ApiHandlerConfig): Promise<void> {
    const alertConfig: ApiAlertConfig = {
      successAlert() {
        return '알림음 순서가 변경되었습니다.';
      },
      errorAlert(e) {
        return new NotiApiException(e).message;
      },
    };

    return this.api.patch(this.url('sequence'), { rows }, { ...alertConfig, ...handlerConfig });
  }

  async delete(id: number): Promise<void> {
    return this.api.delete(this.url(id), {
      successAlert() {
        return '알림음이 삭제되었습니다.';
      },
      errorAlert(e) {
        return new NotiApiException(e).message;
      },
    });
  }
}

export const notiApiService = new NotiApiService('noti-sounds');
