import { FC, SyntheticEvent, useCallback, useState } from 'react';
import ReactSwitch, { ReactSwitchProps } from 'react-switch';

export const ToggleSwitch: FC<ReactSwitchProps> = ({ id, checked, onChange, ...props }) => {
  const [state, setState] = useState<boolean>(checked);

  const onChangeWithTimeout = useCallback(
    (checked: boolean, event: MouseEvent | SyntheticEvent<MouseEvent | KeyboardEvent, Event>, id: string) => {
      setState(checked);
      setTimeout(() => {
        onChange(checked, event, id);
      }, 300);
    },
    [setState, onChange],
  );

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <ReactSwitch
        id={id}
        uncheckedIcon={false}
        checkedIcon={false}
        onColor="#626ed4"
        checked={state}
        onChange={onChangeWithTimeout}
        {...props}
      />
    </div>
  );
};
