import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { LayoutHeader } from './header';
import { LayoutSidebar } from './sidebar';

const ids = { div: 'layout-wrapper' };
const classes = { main: 'main-content' };

export const Layout: FC = () => {
  return (
    <div id={ids.div}>
      <LayoutHeader />
      <LayoutSidebar />
      <main className={classes.main}>
        <Outlet />
      </main>
    </div>
  );
};
