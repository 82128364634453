import { ApiService } from '@/core/service';
import { MobileDefaultDonationDto } from './types';
import { SettingApiException } from './setting.exaception';

export class SettingApiService extends ApiService {
  async getMobileDefaultDonation(): Promise<MobileDefaultDonationDto> {
    return this.api.get(this.url('default-mobile-donation'));
  }

  async updateMobileDefaultDonation(channelId: number): Promise<void> {
    return this.api.patch(
      this.url('default-mobile-donation'),
      { channelId },
      {
        successAlert() {
          return '모바일 기본 후원 페이지 채널이 변경되었습니다.';
        },
        errorAlert(e) {
          return new SettingApiException(e).message;
        },
      },
    );
  }
}

export const settingApiService = new SettingApiService('settings');
