import { jsonToCode, parseJSON } from './helpers';

export function CodeBlock({ title, str }: { title?: string; str: string }) {
  const json = parseJSON(str);

  if (!json) {
    return <></>;
  }

  const code = jsonToCode(json);

  if (!code) {
    return <></>;
  }

  return (
    <>
      <div
        style={{
          backgroundColor: '#222736',
          padding: '5px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '.3rem .3rem 0 0',
        }}
      >
        <h5 className="font-size-13" style={{ margin: 0 }}>
          {title || 'code'}
        </h5>
      </div>
      <pre style={{ backgroundColor: '#32394e', padding: '10px 20px', borderRadius: '0 0 .3rem .3rem' }}>
        <blockquote style={{ margin: 0 }}>{code}</blockquote>
      </pre>
    </>
  );
}
