import { ApiException } from '@/core';
import { SettingApiError } from './enums';

export class SettingApiException extends ApiException {
  get message(): string {
    let message = this.name;

    switch (this.name) {
      case SettingApiError.NotFoundChannel:
        message = '해당 채널의 계정을 찾을 수 없습니다.';
        break;
    }

    return message;
  }
}
