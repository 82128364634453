import { PaginationTableHeadProps } from './types';

export function PaginationTableHead<D extends object>({ headerGroups }: PaginationTableHeadProps<D>) {
  return (
    <thead style={{ color: '#a6b0cf', backgroundColor: '#32394e', borderColor: '#32394e' }}>
      {headerGroups.map((headerGroup) => {
        const headerProps = headerGroup.getHeaderGroupProps({ className: 'text-nowrap px-2' });

        return (
          <tr {...headerProps}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps({
                  style: {
                    textAlign: 'center',
                    width: column.width,
                    minWidth: column.minWidth,
                    maxWidth: column.maxWidth,
                  },
                })}
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        );
      })}
    </thead>
  );
}
