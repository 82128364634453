import { FC } from 'react';
import { Link } from 'react-router-dom';
import { SidebarSingleItemProps } from './types';

export const SidebarSingleItem: FC<SidebarSingleItemProps> = ({ location, item }) => {
  const { path, icon, title } = item;

  const active = location.pathname.startsWith(path) ? 'mm-active' : '';

  return (
    <li>
      <Link to={path} className={active}>
        <i className={icon} />
        <span>{title}</span>
      </Link>
    </li>
  );
};
