import { ApiService } from '@/core/service';
import { TtsProvider } from './enums';
import { TtsLanguageDto, TtsProviderDto, TtsVoiceCodeDto } from './types';
import { useEffect, useState } from 'react';
import { TtsApiException } from './tts.exception';

export class TtsApiService extends ApiService {
  async getProviders(): Promise<TtsProviderDto[]> {
    return this.api.get(this.url('providers'));
  }

  async getLanguages(provider: TtsProvider | string): Promise<TtsLanguageDto[]> {
    return this.api.get(this.url('languages'), {
      params: { provider },
      errorAlert(e) {
        return new TtsApiException(e).message;
      },
    });
  }

  async getVoiceCodes(provider: TtsProvider | string, language: string): Promise<TtsVoiceCodeDto[]> {
    return this.api.get(this.url('codes'), {
      params: { provider, language },
      errorAlert(e) {
        return new TtsApiException(e).message;
      },
    });
  }

  useTtsProviders(): TtsProviderDto[] {
    const [rows, setRows] = useState<TtsProviderDto[]>([]);

    useEffect(() => {
      this.getProviders().then((rows) => setRows(rows || []));
    }, [setRows]);

    return rows;
  }

  useTtsLanguages(provider: TtsProvider | string): TtsLanguageDto[] {
    const [rows, setRows] = useState<TtsLanguageDto[]>([]);

    useEffect(() => {
      if (provider) {
        this.getLanguages(provider).then((rows) => setRows(rows || []));
      } else {
        setRows([]);
      }
    }, [provider, setRows]);

    return rows;
  }

  useTtsVoiceCodes(provider: TtsProvider | string, language: string): TtsVoiceCodeDto[] {
    const [rows, setRows] = useState<TtsVoiceCodeDto[]>([]);

    useEffect(() => {
      if (provider && language) {
        this.getVoiceCodes(provider, language).then((rows) => setRows(rows || []));
      } else {
        setRows([]);
      }
    }, [provider, language, setRows]);

    return rows;
  }
}

export const ttsApiService = new TtsApiService('tts-api');
