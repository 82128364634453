import { ApiService } from '@/core';

export class PayoutAccountApiService extends ApiService {
  private static instance: PayoutAccountApiService;

  public static of(): PayoutAccountApiService {
    if (!this.instance) {
      this.instance = new PayoutAccountApiService('payouts/accounts');
    }

    return this.instance;
  }

  async getRows() {
    return this.api.get(this.url());
  }
}
