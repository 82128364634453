import { Input, Label } from 'reactstrap';
import { ContentModalRadioGroupProps, ContentModalRadioProps } from './types';
import { v4 } from 'uuid';

export function ContentModalRadio({ label, current, ...props }: ContentModalRadioProps) {
  const id = v4();

  return (
    <div className="form-check form-check-inline font-size-16">
      <Input
        {...props}
        id={id}
        type="radio"
        className="form-check-input"
        checked={props.value === current}
        style={{ cursor: 'pointer' }}
      />
      <Label htmlFor={id} className="form-check-label font-size-13">
        {label}
      </Label>
    </div>
  );
}

export function ContentModalRadioGroups({ name, current, onChange, radioProps }: ContentModalRadioGroupProps) {
  return (
    <div className="form-group mb-4">
      {radioProps.map((props, i) => (
        <ContentModalRadio
          {...props}
          key={['content-modal-radio', i].join('-')}
          name={name}
          current={current}
          onChange={onChange}
        />
      ))}
    </div>
  );
}
