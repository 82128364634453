import { Table } from 'reactstrap';
import { SimpleTableProps } from './types';
import { SimpleTableHead } from './simple-table.head';
import { SimpleTableBody } from './simple-table.body';
import { useTable } from 'react-table';

export function SimpleTable<D extends object>({
  alias,
  data,
  columns,
  title,
  description,
  emptyText,
  onClickRowHandler,
}: SimpleTableProps<D>) {
  const { headerGroups, getTableProps, getTableBodyProps, rows, prepareRow } = useTable<D>({
    columns,
    data,
  });

  const tableProps = getTableProps();
  const tableBodyProps = getTableBodyProps();

  return (
    <div className="table-responsive">
      {title && <h4 className="card-title">{title}</h4>}
      {description && <p className="card-title-desc">{description}</p>}
      <Table className="react-table" {...tableProps}>
        <SimpleTableHead headerGroups={headerGroups} />
        <SimpleTableBody
          alias={alias}
          tableBodyProps={tableBodyProps}
          rows={rows}
          columns={columns}
          prepareRow={prepareRow}
          emptyText={emptyText}
          onClickRowHandler={onClickRowHandler}
        />
      </Table>
    </div>
  );
}
