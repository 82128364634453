import { useCallback } from 'react';
import { CellProps } from 'react-table';

import { ISOToString, ListDialogStore, ListDialogStoreValueType, LuxonFormat, NumberToDollar } from '@/core';
import { OnChangeToggleSwitch, SimpleTableColumnType, ToggleSwitch, rootAlertStore } from '@/components';
import {
  StickiesApiService,
  StickiesStatus,
  StickiesDto,
  stickiesApiService,
  CreateStickiesBodyDto,
  UpdateStickiesBodyDto,
} from '@/service';

export class StickiesStore extends ListDialogStore<
  StickiesDto,
  ListDialogStoreValueType<StickiesDto>,
  StickiesApiService
> {
  useColumns(): SimpleTableColumnType<StickiesDto>[] {
    const setRows = this.useSetRows();

    return [
      {
        accessor: 'grossAmount',
        Header: '충전 금액',
        Cell: ({ value }) => <>{NumberToDollar(value)}</>,
      },
      {
        accessor: 'discountRate',
        Header: '할인율',
        Cell: ({ value }) => <>{value.toFixed(2)}%</>,
      },
      {
        accessor: 'discountAmount',
        Header: '할인 금액',
        Cell: ({ value }) => <>{NumberToDollar(value)}</>,
      },
      {
        accessor: 'netAmount',
        Header: '결제 금액',
        Cell: ({ value }) => <>{NumberToDollar(value)}</>,
      },
      {
        id: 'status',
        Header: '활성/비활성',
        minWidth: 80,
        maxWidth: 80,
        Cell: ({ row }: CellProps<StickiesDto>) => {
          const id = row.original.id;

          const onChangeStatus: OnChangeToggleSwitch = useCallback(
            async (checked) => {
              const status = checked ? StickiesStatus.ACTIVE : StickiesStatus.DISABLED;

              await this.service.updateStatus(id, status);
              await setRows();
            },
            [id, setRows],
          );

          return (
            <ToggleSwitch
              width={36}
              height={18}
              checked={row.original.status === StickiesStatus.ACTIVE}
              onChange={onChangeStatus}
            />
          );
        },
      },
      {
        accessor: 'createdAt',
        Header: '등록일자',
        minWidth: 100,
        maxWidth: 100,
        Cell: ({ value }) => <>{value ? ISOToString(value, LuxonFormat.Day) : '-'}</>,
      },
      {
        id: 'actions',
        minWidth: 100,
        maxWidth: 100,
        Cell: ({ row }: CellProps<StickiesDto>) => {
          const id = row.original.id;

          const activeUpdate = this.useActiveUpdate();
          const activeDelete = this.useActiveDelete();

          const onClickUpdate = useCallback(() => activeUpdate(id), [id, activeUpdate]);
          const onClickDelete = useCallback(() => activeDelete(id), [id, activeDelete]);

          return (
            <div>
              <button onClick={onClickUpdate}>
                <i className="bx fs-4 bx-pencil custom-icon" />
              </button>
              <button onClick={onClickDelete}>
                <i className="bx fs-4 bx-trash text-danger custom-icon" />
              </button>
            </div>
          );
        },
      },
    ];
  }

  useOnClickCreateHandler(body: CreateStickiesBodyDto): () => Promise<void> {
    const setAlert = rootAlertStore.useSetState();
    const setRows = this.useSetRows();
    const disableCreate = this.useDisableCreateMode();

    return useCallback(async () => {
      let message = '';

      if (!body.grossAmount) {
        message = '충전 금액을 입력하세요.';
      }

      if (message) {
        setAlert({ type: 'warning', message });
        return;
      }

      await this.service.create(body);
      await setRows();

      disableCreate();
    }, [body, setAlert, setRows, disableCreate]);
  }

  useOnClickUpdateHandler(body: UpdateStickiesBodyDto): () => Promise<void> {
    const setRows = this.useSetRows();
    const updateId = this.useUpdateTarget();
    const disableUpdate = this.useDisableUpdate();

    return useCallback(async () => {
      if (!updateId) {
        return;
      }

      await this.service.update(updateId, body);
      await setRows();

      disableUpdate();
    }, [updateId, body, setRows, disableUpdate]);
  }

  useOnClickDeleteHandler(): () => Promise<void> {
    const setRows = this.useSetRows();
    const deleteId = this.useDeleteTarget();
    const disableDelete = this.useDisableDelete();

    return useCallback(async () => {
      if (!deleteId) {
        return;
      }

      await this.service.delete(deleteId);
      await setRows();

      disableDelete();
    }, [deleteId, setRows, disableDelete]);
  }
}

export const stickiesStore = new StickiesStore(
  {
    rows: [],
    createMode: false,
  },
  stickiesApiService,
);
