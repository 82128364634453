import { ApiService } from '@/core';

import { ChargeStickiesBodyDto } from './types';
import { StickiesChargeApiException } from './stickies-charge-api.exception';

export class StickiesChargeApiService extends ApiService {
  public static of() {
    return new StickiesChargeApiService('stickies-charge');
  }

  async change(body: ChargeStickiesBodyDto): Promise<void> {
    return this.api.post(this.url(), body, {
      successAlert: () => '스티키 충전이 완료되었습니다.',
      errorAlert: (e) => StickiesChargeApiException.of(e).message,
    });
  }
}

export const stickiesChargeApiService = StickiesChargeApiService.of();
