export enum NotiCategoryStatus {
  Active = 'active',
  Disable = 'disable',
}

export enum NotiType {
  Default = 'default',
  Conditional = 'conditional',
}

export enum NotiStatus {
  Active = 'active',
  Disable = 'disable',
}

export enum NotiCategoryApiError {
  AlreadyExistNotiSoundCategory = 'AlreadyExistNotiSoundCategory',
  NotFoundNotiSoundCategory = 'NotFoundNotiSoundCategory',
  IncludedDefaultNotiSoundException = 'IncludedDefaultNotiSoundException',
}

export enum NotiApiError {
  NotFoundNotiSoundCategory = 'NotFoundNotiSoundCategory',
  NotFoundNotiSound = 'NotFoundNotiSound',
  CannotUpdateDefaultNotiSound = 'CannotUpdateDefaultNotiSound',
  CannotDeleteDefaultNotiSound = 'CannotDeleteDefaultNotiSound',
}
