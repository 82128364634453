import UniversalCookie, { CookieSetOptions } from 'universal-cookie';
import { CookieTokenDto } from './dtos';

export class Cookie extends UniversalCookie {
  private readonly SET_OPTIONS: CookieSetOptions = {
    path: '/',
    maxAge: 86400,
  };

  private get ACCESS_TOKEN(): string {
    return 'access_token';
  }

  private get REFRESH_TOKEN(): string {
    return 'refresh_token';
  }

  getTokens(): CookieTokenDto {
    return new CookieTokenDto(this.getAccessToken(), this.getRefreshToken());
  }

  getAccessToken(): string | null {
    return this.get(this.ACCESS_TOKEN);
  }

  getRefreshToken(): string | null {
    return this.get(this.REFRESH_TOKEN);
  }

  setTokens(accessToken: string, refreshToken: string): void {
    this.setAccessToken(accessToken);
    this.setRefreshToken(refreshToken);
  }

  setAccessToken(accessToken: string): void {
    this.set(this.ACCESS_TOKEN, accessToken, this.SET_OPTIONS);
  }

  setRefreshToken(refreshToken: string): void {
    this.set(this.REFRESH_TOKEN, refreshToken, this.SET_OPTIONS);
  }

  removeTokens(): void {
    this.removeAccessToken();
    this.removeRefreshToken();
  }

  removeAccessToken(): void {
    this.remove(this.ACCESS_TOKEN, this.SET_OPTIONS);
  }

  removeRefreshToken(): void {
    this.remove(this.REFRESH_TOKEN, this.SET_OPTIONS);
  }
}
