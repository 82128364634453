import classnames from 'classnames';

import { FC, useCallback, useState } from 'react';
import { Collapse, Row } from 'reactstrap';

import { AccordionProps } from './types';

export const Accordion: FC<AccordionProps> = ({ children, title, ...props }) => {
  const [open, setOpen] = useState<boolean>(props.open || false);

  const onClick = useCallback(() => {
    setOpen((prev) => !prev);
  }, [setOpen]);

  return (
    <Row>
      <div className="accordion" id="accordion" style={{ marginBottom: '24px' }}>
        <div className="accordion-item" style={{ background: '#2a3042' }}>
          <h2 className="accordion-header" id="headingOne">
            <button
              className={classnames('accordion-button', 'fw-medium', { collapsed: !open })}
              type="button"
              onClick={onClick}
              style={{
                cursor: 'pointer',
                fontSize: '15px',
                fontWeight: 700,
                width: '-webkit-fill-available',
              }}
            >
              {title}
            </button>
          </h2>

          <Collapse isOpen={open} className="accordion-collapse">
            <div className="accordion-body">{children}</div>
          </Collapse>
        </div>
      </div>
    </Row>
  );
};
