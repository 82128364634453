import ReactSelect from 'react-select';

import { AccordionSelectProps } from './types';

export function AccordionSelect({ options, selected, onSelect, selectPlaceholder }: AccordionSelectProps) {
  const current = options.find((option) => option.value === selected);

  return (
    <ReactSelect
      classNamePrefix="select2-selection"
      value={current}
      options={options}
      onChange={onSelect}
      placeholder={selectPlaceholder}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          neutral10: '#c3cbe4',
          neutral80: 'white',
          primary: '#556ee6',
          primary25: '#32394e',
        },
      })}
      styles={{
        singleValue: (props) => {
          props.minWidth = '60px';
          return props;
        },
        menu: (props) => {
          props.minWidth = '60px';
          return props;
        },
        menuList: (props) => {
          props.minWidth = '60px';
          return props;
        },
      }}
    />
  );
}
