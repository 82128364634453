import { FC } from 'react';
import { Col } from 'reactstrap';
import { useAvatarTheme } from './hooks';
import { SidebarAvatarLarge } from './sidebar.avatar.large';
import { SidebarAvatarSmall } from './sidebar.avatar.small';

export const SidebarAvatar: FC = () => {
  useAvatarTheme();

  return (
    <Col className="text-center logo">
      <SidebarAvatarSmall />
      <SidebarAvatarLarge />
    </Col>
  );
};
