import { FC, useCallback } from 'react';
import { AuthUpdatePasswordBodyDto, authApiService } from '@/service';
import { HeaderPasswordModalActionProps } from './types';
import { rootAlertStore } from '@/components/root';

const classes = {
  div: 'modal-footer',
  button: {
    primary: 'btn btn-primary',
    light: 'btn btn-light',
  },
};

export const HeaderPasswordModalAction: FC<HeaderPasswordModalActionProps> = ({ body, error, setError, onToggle }) => {
  const setAlert = rootAlertStore.useSetState();
  const canUpdate = !!Object.values(body).find((val) => !!val);

  const validate = useCallback((body: AuthUpdatePasswordBodyDto) => {
    if (!body.currentPassword) {
      return '현재 비밀번호를 입력하세요.';
    }

    if (!body.newPassword) {
      return '새 비밀번호를 입력하세요.';
    }

    if (!body.confirmPassword) {
      return '새 비밀번호를 다시 입력하세요.';
    }

    if (body.newPassword !== body.confirmPassword) {
      return '새 비밀번호가 일치하지 않습니다.';
    }
  }, []);

  const onClick = useCallback(async () => {
    const message = validate(body);

    if (message) {
      setAlert({ type: 'warning', message });
      return;
    }

    await authApiService.updatePassword(body);
    onToggle();
  }, [body, validate, onToggle, setAlert]);

  return (
    <div className={classes.div}>
      <button className={classes.button.primary} onClick={onClick} disabled={!canUpdate && !!error}>
        확인
      </button>
      <button className={classes.button.light} onClick={onToggle}>
        취소
      </button>
    </div>
  );
};
