export const parseJSON = (str: string): Object | undefined => {
  let json: Object | undefined;

  try {
    json = JSON.parse(str);
  } catch {
    json = undefined;
  }

  return json;
};

export const jsonToCode = (json: Object | JSON): string | undefined => {
  let code: string | undefined;

  try {
    code = JSON.stringify(json, null, 2);
  } catch (e) {
    console.log(e);
  }

  return code;
};
