import { Table } from 'reactstrap';
import { useTable } from 'react-table';
import DragListView from 'react-drag-listview';
import { DragDropTableProps } from './types';
import { DragDropTableHead } from './drag-drop-table.head';
import { DragDropTableBody } from './drag-drop-table.body';

export function DragDropTable<D extends object>({
  data,
  columns,
  title,
  description,
  emptyText,
  onClickRowHandler,
  ...props
}: DragDropTableProps<D>) {
  const { headerGroups, getTableProps, getTableBodyProps, rows, prepareRow } = useTable<D>({
    columns,
    data,
  });

  const tableProps = getTableProps();
  const tableBodyProps = getTableBodyProps();

  const table = (
    <Table className="react-table" {...tableProps}>
      <DragDropTableHead headerGroups={headerGroups} />
      <DragDropTableBody
        tableBodyProps={tableBodyProps}
        rows={rows}
        columns={columns}
        prepareRow={prepareRow}
        emptyText={emptyText}
        onClickRowHandler={onClickRowHandler}
      />
    </Table>
  );

  return (
    <div className="table-responsive">
      {title && <h4 className="card-title">{title}</h4>}
      {description && <p className="card-title-desc">{description}</p>}
      {rows.length === 0 ? table : <DragListView {...props}>{table}</DragListView>}
    </div>
  );
}
