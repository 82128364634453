import { SortOrder } from '@/core';
import { PaginationTableSortOrderProps } from './types';
import { ChangeEventHandler, useCallback } from 'react';

export function PaginationTableSortOrderSelector<T>({ sort, setQuery }: PaginationTableSortOrderProps<T>) {
  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setQuery((prev) => ({
        ...prev,
        query: { ...prev.query, sortOrder: e.target.value as SortOrder },
      }));
    },
    [setQuery],
  );

  return (
    <div style={{ float: 'right' }}>
      <div className="btn-group-sm" role="group">
        <input
          id="sort-order-desc"
          type="radio"
          className="btn-check"
          autoComplete="off"
          value={SortOrder.DESC}
          checked={sort === SortOrder.DESC}
          onChange={onChange}
        />
        <label className="btn btn-outline-secondary" htmlFor="sort-order-desc" style={{marginRight: 5}}>
          DESC(Date)
        </label>
        <input
          id="sort-order-asc"
          type="radio"
          className="btn-check"
          autoComplete="off"
          value={SortOrder.ASC}
          checked={sort === SortOrder.ASC}
          onChange={onChange}
        />
        <label className="btn btn-outline-secondary" htmlFor="sort-order-asc">
          ASC(Date)
        </label>
      </div>
    </div>
  );
}
