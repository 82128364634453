import { ApiException } from '@/core';
import { UserApiError } from './enums';

export class UserApiException extends ApiException {
  get message(): string {
    let message = this.name;

    switch (this.name) {
      case UserApiError.NOT_FOUND_ERROR:
        message = '사용자를 찾을 수 없습니다.';
        break;
    }

    return message;
  }
}
