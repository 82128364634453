import { MouseEventHandler, useCallback } from 'react';
import { ListDialogStore, AwsS3Bucket, ISOToString, LuxonFormat, config } from '@/core';
import {
  ToggleSwitch,
  DragDropEndEventHandler,
  DragDropTableColumnType,
  OnChangeToggleSwitch,
  getUpdateSequenceTargets,
} from '@/components';
import { TtsProvider, TtsStatus, TtsVoiceApiService, TtsVoiceDto, ttsVoiceApiService } from '@/service';
import { TtsVoiceStoreValueType } from './types';

export class TtsVoiceStore extends ListDialogStore<TtsVoiceDto, TtsVoiceStoreValueType, TtsVoiceApiService> {
  private useColumnHandlers() {
    const setRows = this.useSetRows();
    const activeUpdate = this.useActiveUpdate();
    const activeDelete = this.useActiveDelete();

    const onChangeIsNewHandler = useCallback(
      (row: TtsVoiceDto): OnChangeToggleSwitch =>
        async (checked) => {
          await this.service.updateIsNew(row.id, checked);
          await setRows();
        },
      [setRows],
    );

    const onChangeStatusHandler = useCallback(
      (row: TtsVoiceDto): OnChangeToggleSwitch =>
        async (checked) => {
          const status = checked ? TtsStatus.Active : TtsStatus.Disable;
          await this.service.updateStatus(row.id, status);
          await setRows();
        },
      [setRows],
    );

    const onClickUpdateHandler = useCallback(
      (id: number): MouseEventHandler<HTMLButtonElement> => {
        return () => activeUpdate(id);
      },
      [activeUpdate],
    );

    const onClickDeleteHandler = useCallback(
      (id: number): MouseEventHandler<HTMLButtonElement> => {
        return () => activeDelete(id);
      },
      [activeDelete],
    );

    return { onChangeIsNewHandler, onChangeStatusHandler, onClickUpdateHandler, onClickDeleteHandler };
  }

  useColumns(): DragDropTableColumnType<TtsVoiceDto>[] {
    const providerText: Record<TtsProvider | string, string> = {
      google: '구글',
    };

    const { onChangeIsNewHandler, onChangeStatusHandler, onClickUpdateHandler, onClickDeleteHandler } =
      this.useColumnHandlers();

    return [
      {
        accessor: 'sequence',
        Header: '',
        minWidth: 20,
        maxWidth: 20,
        Cell: () => <i className="fa fa-fw fa-bars" />,
      },
      {
        accessor: 'id',
        Header: 'ID',
        minWidth: 40,
        maxWidth: 40,
      },
      {
        accessor: 'imagePath',
        Header: '이미지',
        minWidth: 80,
        maxWidth: 80,
        Cell: ({ value }) => (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div
              className="avatar-md rounded bg-light"
              style={{
                background: `url(${value && config.S3_URL(AwsS3Bucket.TtsImage, value)})`,
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundPositionX: 'center',
              }}
            />
          </div>
        ),
      },
      {
        accessor: 'name',
        Header: '이름',
        minWidth: 120,
        maxWidth: 120,
      },
      {
        accessor: 'provider',
        Header: '제공사',
        minWidth: 60,
        maxWidth: 60,
        Cell: ({ value }) => <>{providerText[value]}</>,
      },
      {
        accessor: 'language',
        Header: '언어',
        minWidth: 100,
        maxWidth: 100,
      },
      {
        accessor: 'code',
        Header: '코드',
        minWidth: 120,
        maxWidth: 120,
      },
      {
        accessor: 'samplePath',
        Header: '미리듣기',
        Cell: ({ value }) => <audio controls src={config.S3_URL(AwsS3Bucket.TtsSample, value)} />,
      },
      {
        accessor: 'createdAt',
        Header: '생성일시',
        minWidth: 120,
        maxWidth: 120,
        Cell: ({ value }) => <>{ISOToString(value, LuxonFormat.Second)}</>,
      },
      {
        accessor: 'isNew',
        Header: 'new',
        minWidth: 70,
        maxWidth: 70,
        Cell: ({ row }) => (
          <ToggleSwitch
            width={36}
            height={18}
            checked={row.original.isNew}
            onChange={onChangeIsNewHandler(row.original)}
          />
        ),
      },
      {
        accessor: 'status',
        Header: '상태',
        minWidth: 70,
        maxWidth: 70,
        Cell: ({ row }) => (
          <ToggleSwitch
            width={36}
            height={18}
            checked={row.original.status === TtsStatus.Active}
            onChange={onChangeStatusHandler(row.original)}
          />
        ),
      },
      {
        id: 'actions',
        minWidth: 100,
        maxWidth: 100,
        Cell: ({ row }: { row: { original: TtsVoiceDto } }) => (
          <div>
            <button onClick={onClickUpdateHandler(row.original.id)}>
              <i className="bx fs-4 bx-pencil custom-icon" />
            </button>

            <button onClick={onClickDeleteHandler(row.original.id)}>
              <i className="bx fs-4 bx-trash text-danger custom-icon" />
            </button>
          </div>
        ),
      },
    ];
  }

  useOnUpdateSequence(): DragDropEndEventHandler {
    const rows = this.useRows();
    const setRows = this.useSetRows();

    return useCallback(
      async (startIndex, endIndex) => {
        const targets = getUpdateSequenceTargets(rows, startIndex, endIndex);

        if (targets.length === 0) {
          return;
        }

        await this.service.updateSequence(targets);
        await setRows();
      },
      [rows, setRows],
    );
  }
}

export const ttsVoiceStore = new TtsVoiceStore(
  {
    rows: [],
    createMode: false,
    updateId: undefined,
    deleteId: undefined,
  },
  ttsVoiceApiService,
);
