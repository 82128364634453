export class ImageRegExp extends RegExp {
  constructor() {
    super(/(\.gif|\.jpg|\.jpeg|\.jpng|\.png|\.webp)$/i);
  }

  validate(filename: string) {
    if (!this.test(filename)) {
      return '이미지 형식에 맞지 않습니다.';
    }
  }
}

export class StickerRegExp extends RegExp {
  constructor() {
    super(/(\.gif|\.jpg|\.jpeg|\.jpng|\.png|\.webp|\.webm|\.mp4)$/i);
  }

  validate(filename: string) {
    if (!this.test(filename)) {
      return '스티커 형식에 맞지 않습니다.';
    }
  }
}

export class SoundRegExp extends RegExp {
  constructor() {
    super(/(\.mp3|\.wav)$/i);
  }

  validate(filename: string) {
    if (!this.test(filename)) {
      return '음원 파일 형식에 맞지 않습니다.';
    }
  }
}

export const imageRegExp = new ImageRegExp();
export const stickerRegExp = new StickerRegExp();
export const soundRegExp = new SoundRegExp();
