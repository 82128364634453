import { ApiService, ListApiService } from '@/core';
import { CreateStickiesBodyDto, StickiesDto, UpdateStickiesBodyDto } from './types';
import { StickiesApiException } from './stickies-api.exception';
import { StickiesStatus } from './enums';

export class StickiesApiService extends ApiService implements ListApiService<StickiesDto> {
  async getRows(): Promise<StickiesDto[]> {
    return this.api.get(this.url());
  }

  async create(body: CreateStickiesBodyDto): Promise<void> {
    return this.api.post(this.url(), body, {
      successAlert: () => '충전 결제 항목이 등록되었습니다.',
      errorAlert: (e) => new StickiesApiException(e).message,
    });
  }

  async update(id: number, body: UpdateStickiesBodyDto): Promise<void> {
    return this.api.patch(this.url(id), body, {
      successAlert: () => '충전 결제 항목이 수정되었습니다.',
      errorAlert: (e) => new StickiesApiException(e).message,
    });
  }

  async updateStatus(id: number, status: StickiesStatus): Promise<void> {
    return this.api.patch(
      this.url(id),
      { status },
      {
        successAlert: () => '충전 결제 항목 상태가 변경되었습니다.',
        errorAlert: (e) => new StickiesApiException(e).message,
      },
    );
  }

  async delete(id: number): Promise<void> {
    return this.api.delete(this.url(id), {
      successAlert: () => '충전 결제 항목이 삭제되었습니다.',
      errorAlert: (e) => new StickiesApiException(e).message,
    });
  }
}

export const stickiesApiService = new StickiesApiService('stickies');
