import { useCallback, useEffect } from 'react';
import { Store } from '@/core';
import { MobileDefaultDonationDto, SettingApiService, UserSearchDto, settingApiService } from '@/service';
import { MobileSettingStoreValueType } from './types';

export class MobileSettingStore extends Store<MobileSettingStoreValueType, SettingApiService> {
  useInitUser(): void {
    const setUser = this.useSetUser();

    useEffect(() => {
      setUser();
    }, [setUser]);
  }

  useSetUser(): () => Promise<void> {
    const setState = this.useSetState();

    return useCallback(async () => {
      const user = await this.service.getMobileDefaultDonation();
      setState((prev) => ({ ...prev, user }));
    }, [setState]);
  }

  useUser(): MobileDefaultDonationDto | null {
    return this.useValue().user;
  }

  useOnClickUpdate(body: UserSearchDto): () => Promise<void> {
    const setUser = this.useSetUser();

    return useCallback(async () => {
      if (!body.id) {
        return;
      }

      await this.service.updateMobileDefaultDonation(body.id);
      await setUser();
    }, [body, setUser]);
  }
}

export const mobileSettingStore = new MobileSettingStore({ user: null }, settingApiService);
