import { Input } from 'reactstrap';
import { ContentModalInputProps } from './types';

export function ContentModalInput({ label, inputStyle, tailLabel, tailLabelStyle, ...props }: ContentModalInputProps) {
  return (
    <div className="form-group mb-4">
      {label && <label>{label}</label>}
      <div className="d-flex align-items-center">
        <Input {...props} autoComplete="off" style={{ height: '38px', color: '#fff', ...inputStyle }} />
        {tailLabel && <label style={{ margin: 0, ...tailLabelStyle }}>{tailLabel}</label>}
      </div>
    </div>
  );
}
