import { PayoutStatus } from './enums';

export class PayoutService {
  private static instance: PayoutService;

  public static of(): PayoutService {
    if (!this.instance) {
      this.instance = new PayoutService();
    }

    return this.instance;
  }

  getTextByStatus(status: PayoutStatus, canActive?: boolean): string {
    let text;

    switch (status) {
      case PayoutStatus.WAITING:
        text = 'Impossible';

        if (canActive) {
          text = 'Waiting';
        }

        break;

      case PayoutStatus.PROCESSING:
        text = 'Processing';
        break;

      case PayoutStatus.TRANSFERRED:
        text = 'Completed';
        break;

      default:
        text = 'Failed';
        break;
    }

    return text;
  }

  getOverTextByStatus(status: PayoutStatus): string {
    let text = '';

    switch (status) {
      case PayoutStatus.TRANSFERRED:
        text = 'Completed';
        break;
    }

    return text;
  }

  getClassNameByStatus(status: PayoutStatus, canPayout?: boolean): string {
    let className = '';

    switch (status) {
      case PayoutStatus.WAITING:
        if (canPayout) {
          className = 'btn-outline-info';
        }

        break;

      case PayoutStatus.TRANSFERRED:
        className = 'btn-outline-info';
        break;

      case PayoutStatus.PROCESSING:
        className = 'btn-outline-warning';
        break;

      default:
        className = 'btn-outline-danger';
        break;
    }

    return className;
  }

  getOverClassNameByStatus(status: PayoutStatus): string {
    let className = '';

    switch (status) {
      case PayoutStatus.TRANSFERRED:
        className = 'btn-outline-info';
        break;
    }

    return className;
  }
}
