import { ChangeEvent, FC, useCallback, useState } from 'react';
import { Modal } from 'reactstrap';
import { AuthUpdatePasswordBodyDto } from '@/service';
import { HeaderPasswordModalProps } from './types';
import { initUpdatePasswordBody } from './constants';
import { HeaderPasswordModalButton } from './password.modal.button';
import { HeaderPasswordModalAction } from './password.modal.action';
import { HeaderPasswordModalInput } from './password.modal.input';

export const HeaderPasswordModal: FC<HeaderPasswordModalProps> = ({ open, onToggle }) => {
  const [body, setBody] = useState<AuthUpdatePasswordBodyDto>(initUpdatePasswordBody);
  const [error, setError] = useState<string>('');

  const onClose = useCallback(() => {
    setBody(initUpdatePasswordBody);
    setError('');
  }, [setBody]);

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setBody((prev) => ({ ...prev, [name]: value }));
      setError('');
    },
    [setBody, setError],
  );

  return (
    <Modal centered isOpen={open} toggle={onToggle} onClosed={onClose}>
      <HeaderPasswordModalButton onToggle={onToggle} />
      <div className="modal-body text-center">
        <HeaderPasswordModalInput
          labelText="현재 비밀번호"
          placeholder="현재 비밀번호를 입력하세요."
          name="currentPassword"
          value={body.currentPassword}
          error={error}
          onChange={onChange}
        />
        <HeaderPasswordModalInput
          labelText="새 비밀번호"
          placeholder="새 비밀번호를 입력하세요."
          name="newPassword"
          value={body.newPassword}
          error={error}
          onChange={onChange}
        />
        <HeaderPasswordModalInput
          labelText="비밀번호 확인"
          placeholder="새 비밀번호를 다시 입력하세요."
          name="confirmPassword"
          value={body.confirmPassword}
          error={error}
          onChange={onChange}
        />
      </div>
      <HeaderPasswordModalAction body={body} error={error} setError={setError} onToggle={onToggle} />
    </Modal>
  );
};
