import axios, { Axios, AxiosError, InternalAxiosRequestConfig } from 'axios';
import { Config } from '../config';
import { Cookie } from '../cookie';
import { PagePath } from '../page';
import {
  ApiAlertStoreValueType,
  ApiExceptionType,
  ApiRequestConfig,
  ApiWithDataMethod,
  ApiWithoutDataMethod,
} from './types';
import { SetterOrUpdater } from 'recoil';

export class Api {
  private readonly axios: Axios;

  constructor(private readonly config: Config, private readonly cookie: Cookie) {
    this.axios = axios.create({
      baseURL: this.config.BASE_URL,
      withCredentials: false,
      timeout: 20000,
    });
  }

  public get get(): ApiWithoutDataMethod {
    return this.axios.get;
  }

  public get post(): ApiWithDataMethod {
    return this.axios.post;
  }

  public get patch(): ApiWithDataMethod {
    return this.axios.patch;
  }

  public get put(): ApiWithDataMethod {
    return this.axios.put;
  }

  public get delete(): ApiWithoutDataMethod {
    return this.axios.delete;
  }

  public get head(): ApiWithoutDataMethod {
    return this.axios.head;
  }

  public useInterceptors(setLoader: any, setAlert: any) {
    this.requestInterceptor(setLoader, setAlert);
    this.responseInterceptor(setLoader, setAlert);
  }

  private requestInterceptor(setLoader: any, setAlert: any) {
    this.axios.interceptors.request.use(
      (config: InternalAxiosRequestConfig & ApiRequestConfig) => {
        setLoader(true);

        const accessToken = this.cookie.getAccessToken();

        if (accessToken) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }

        return config;
      },
      (error) => {
        setLoader(true);
        setAlert({
          type: 'danger',
          message: 'Axios Request Fail Error',
        });

        return Promise.reject(error);
      },
    );
  }

  private responseInterceptor(
    setLoader: SetterOrUpdater<boolean>,
    setAlert: SetterOrUpdater<ApiAlertStoreValueType>,
  ): void {
    this.axios.interceptors.response.use(
      (response) => {
        setLoader(false);

        const config = response?.config as ApiRequestConfig;

        if (!config) {
          return response.data;
        }

        if (config.successAlert) {
          const message = config.successAlert();
          setAlert({ type: 'info', message });
        }

        if (config.successHandler) {
          config.successHandler();
        }

        if (config.finalHandler) {
          config.finalHandler();
        }

        return response.data;
      },
      (error: AxiosError) => {
        setLoader(false);

        if (!error.response) {
          return Promise.reject(error);
        }

        const status = error.response.status;
        const data = error.response.data as ApiExceptionType;
        const config = error.response.config as ApiRequestConfig;

        if (status === 401 && window.location.pathname !== PagePath.SignIn) {
          this.cookie.removeAccessToken();
          return window.location.replace(PagePath.SignIn);
        }

        if (status === 400 && data.error.name === 'InvalidAccessToken') {
          this.cookie.removeAccessToken();
          return window.location.replace(PagePath.SignIn);
        }

        if (status === 403 && data.error.name === 'Forbidden') {
          return window.location.replace(PagePath.Forbidden);
        }

        if (config.errorAlert) {
          const message = config.errorAlert(data);
          setAlert({ type: 'danger', message });
        }

        if (config.errorHandler) {
          config.errorHandler();
        }

        if (config.finalHandler) {
          config.finalHandler();
        }

        return Promise.reject(error.response.data);
      },
    );
  }
}
