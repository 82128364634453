import { ApiService, ListApiService } from '@/core/service';
import { CreateStaffBodyDto, StaffDto } from './types';
import { StaffApiException } from './staff.exception';

export class StaffApiService extends ApiService implements ListApiService<StaffDto> {
  async getRows(): Promise<StaffDto[]> {
    return this.api.get(this.url());
  }

  async create(body: CreateStaffBodyDto): Promise<void> {
    return this.api.post(this.url(), body, {
      successAlert() {
        return '계정이 생성되었습니다.';
      },
      errorAlert(e) {
        return new StaffApiException(e).message;
      },
    });
  }

  async delete(id: number): Promise<void> {
    return this.api.delete(this.url(id), {
      successAlert() {
        return '계정이 삭제되었습니다.';
      },
      errorAlert(e) {
        return new StaffApiException(e).message;
      },
    });
  }
}

export const staffApiService = new StaffApiService('staff');
