import { PagePath } from '@/core';
import { SidebarItemType } from './types';

export const sidebarItems: SidebarItemType[] = [
  {
    path: PagePath.LiveStream,
    title: '실시간 방송(Live Streams)',
    icon: 'bx bx-broadcast',
    policies: ['live'],
    branch: [],
  },
  {
    path: PagePath.IAM,
    title: '역할 및 계정 관리(Staffs)',
    icon: 'bx bx-key',
    policies: ['master'],
    branch: [],
  },
  {
    path: PagePath.Service,
    title: '서비스 설정(Settings)',
    icon: 'bx bx-slider-alt',
    policies: ['setting', 'maintenance'],
    branch: [
      {
        path: PagePath.ServiceSetting,
        title: '기본 설정(Service)',
        icon: 'bx bx-cog',
        policies: ['setting'],
      },
      {
        path: PagePath.ServiceMaintenance,
        title: '점검 알림(Inspection)',
        icon: 'bx bx-time',
        policies: ['maintenance'],
      },
    ],
  },
  {
    path: PagePath.Asset,
    title: '자원 관리(Resources)',
    icon: 'bx bx-folder-open',
    policies: ['noti', 'tts', 'sticker'],
    branch: [
      {
        path: PagePath.AssetNoti,
        title: '알림음 관리(Sounds)',
        icon: 'bx bx-bell',
        policies: ['noti'],
      },
      {
        path: PagePath.AssetTts,
        title: 'TTS 관리(TTS)',
        icon: 'bx bx-microphone',
        policies: ['tts'],
      },
      {
        path: PagePath.AssetSticker,
        title: '스티커 관리(Stickers)',
        icon: 'bx bx-happy-alt',
        policies: ['sticker'],
      },
    ],
  },
  {
    path: PagePath.Search,
    title: '검색 목록(Information)',
    icon: 'bx bx-filter-alt',
    policies: ['user', 'donation', 'payout', 'stickies'],
    branch: [
      {
        path: PagePath.SearchUser,
        title: '사용자 목록(Users)',
        icon: 'bx bx-group',
        policies: ['user'],
      },
      {
        path: PagePath.SearchDonation,
        title: '후원 목록(Donation)',
        icon: 'bx bx-like',
        policies: ['donation'],
      },
      {
        path: PagePath.SearchPayout,
        title: '정산 관리(Payouts)',
        icon: 'bx bx-money',
        policies: ['payout'],
      },
    ],
  },
  {
    path: PagePath.Stickies,
    title: '스티키 관리(Stickies)',
    icon: 'bx bx-dollar-circle',
    policies: ['stickies'],
    branch: [
      {
        path: PagePath.StickiesItem,
        title: '스티키 항목(List)',
        icon: 'bx bx-package',
        policies: ['stickies'],
      },
      {
        path: PagePath.StickiesCharge,
        title: '스티키 충전(Charging)',
        icon: 'bx bx-user-check',
        policies: ['master'],
      },
    ],
  },
  {
    path: PagePath.Promotion,
    title: '프로모션(Promotion)',
    icon: 'bx bx-dollar-circle',
    policies: ['promotion'],
    branch: [
      {
        path: PagePath.PromotionFreeCredit,
        title: 'Free Credits',
        icon: 'bx bx-gift',
        policies: ['promotion'],
      },
    ],
  },
];
