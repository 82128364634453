import { ApiException } from '@/core';
import { DonationApiError } from './enums';

export class DonationApiException extends ApiException {
  get message(): string {
    let text = this.name;

    switch (this.name) {
      case DonationApiError.NotFoundDonationImage:
        text = '후원 이미지가 존재하지 않습니다.';
        break;
    }

    return text;
  }
}
