export class StickiesService {
  public calcDiscountAmount(targetAmount: number, discountRate?: number): string {
    const amount = parseFloat(String(targetAmount));
    const rate = parseFloat(String(discountRate));

    if (isNaN(amount) || isNaN(rate)) {
      return '0';
    }

    return ((amount * rate) / 100).toFixed(2);
  }

  public calcNetAmount(targetAmount: number, discountRate?: number): string {
    const amount = parseFloat(String(targetAmount));
    const rate = parseFloat(String(discountRate));

    if (isNaN(amount)) {
      return '0';
    }

    if (isNaN(rate)) {
      return amount.toFixed(2);
    }

    return ((amount * (100 - rate)) / 100).toFixed(2);
  }
}

export const stickiesService = new StickiesService();
