import { useCallback } from 'react';
import { CellProps } from 'react-table';

import { AwsS3Bucket, ListQueryStore, NumberToDollar, SortOrder, config } from '@/core';
import {
  StickiesEventDto,
  StickiesEventApiService,
  stickiesEventApiService,
  StickiesEventListQueryDto,
  StickiesEventStatus,
  CreateStickiesEventDto,
  UpdateStickiesEventDto,
} from '@/service';
import { OnChangeToggleSwitch, PaginationTableColumnType, ToggleSwitch, rootAlertStore } from '@/components';

import { StickiesEventStoreValueType } from './types';

export class StickiesEventListStore extends ListQueryStore<
  StickiesEventDto,
  StickiesEventStoreValueType,
  StickiesEventApiService
> {
  public static of() {
    return new StickiesEventListStore(
      {
        total: 0,
        rows: [],
        query: {
          offset: 0,
          limit: 15,
          sortOrder: SortOrder.DESC,
        },
      },
      stickiesEventApiService,
    );
  }

  public useSetRows(): () => Promise<void> {
    return super.useSetRows<StickiesEventListQueryDto>('offset', 'limit', 'sortOrder');
  }

  public useColumns(): PaginationTableColumnType<StickiesEventDto>[] {
    const setRows = this.useSetRows();

    return [
      {
        accessor: 'id',
        Header: '이벤트 ID',
        minWidth: 60,
        maxWidth: 60,
        Cell: ({ value }) => (
          <div className="d-flex" style={{ height: 50, alignItems: 'center', justifyContent: 'center' }}>
            {value}
          </div>
        ),
      },
      {
        accessor: 'creatorId',
        Header: '크리에이터 ID',
        minWidth: 60,
        maxWidth: 60,
        Cell: ({ value }) => (
          <div className="d-flex" style={{ height: 50, alignItems: 'center', justifyContent: 'center' }}>
            {value}
          </div>
        ),
      },
      {
        id: 'creator',
        Header: '크리에이터',
        Cell: ({ row }: CellProps<StickiesEventDto>) => (
          <div
            style={{
              display: 'flex',
              minWidth: 80,
              alignItems: 'center',
              justifyContent: 'center',
              padding: '5px 0',
            }}
          >
            <div
              style={{
                height: 30,
                width: 30,
                margin: '0 10px 0 0',
                borderRadius: '50%',
                background: `url(${config.S3_URL(AwsS3Bucket.Profile, row.original.imagePath)})`,
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundPositionX: 'center',
              }}
            />
            <div>{row.original.nickname}</div>
          </div>
        ),
      },
      {
        accessor: 'total',
        Header: '예산금액',
        minWidth: 100,
        maxWidth: 100,
        Cell: ({ value }) => <>{NumberToDollar(value)}</>,
      },
      {
        accessor: 'per',
        Header: '1회당 지급액',
        minWidth: 100,
        maxWidth: 100,
        Cell: ({ value }) => <>{NumberToDollar(value)}</>,
      },
      {
        accessor: 'usage',
        Header: '지급누적금액',
        minWidth: 100,
        maxWidth: 100,
        Cell: ({ value }) => <>{NumberToDollar(value)}</>,
      },
      {
        accessor: 'remain',
        Header: '지급가능금액',
        minWidth: 100,
        maxWidth: 100,
        Cell: ({ value }) => <>{NumberToDollar(value)}</>,
      },
      {
        id: 'status',
        Header: '활성/비활성',
        minWidth: 80,
        maxWidth: 80,
        Cell: ({ row }: CellProps<StickiesEventDto>) => {
          const id = row.original.id;

          const onChangeStatus: OnChangeToggleSwitch = useCallback(
            async (checked) => {
              const status = checked ? StickiesEventStatus.ACTIVE : StickiesEventStatus.DISABLED;

              await this.service.changeStatus(id, { status });
              await setRows();
            },
            [id, setRows],
          );

          return (
            <ToggleSwitch
              width={36}
              height={18}
              checked={row.original.status === StickiesEventStatus.ACTIVE}
              onChange={onChangeStatus}
            />
          );
        },
      },
      {
        id: 'actions',
        minWidth: 100,
        maxWidth: 100,
        Cell: ({ row }: CellProps<StickiesEventDto>) => {
          const id = row.original.id;

          const activeUpdate = this.useActiveUpdate();
          const activeDelete = this.useActiveDelete();

          const onClickUpdate = useCallback(() => activeUpdate(id), [id, activeUpdate]);
          const onClickDelete = useCallback(() => activeDelete(id), [id, activeDelete]);

          return (
            <div>
              <button onClick={onClickUpdate}>
                <i className="bx fs-4 bx-pencil custom-icon" />
              </button>
              <button onClick={onClickDelete}>
                <i className="bx fs-4 bx-trash text-danger custom-icon" />
              </button>
            </div>
          );
        },
      },
    ];
  }

  public useOnClickCreate(body: CreateStickiesEventDto) {
    const setAlert = rootAlertStore.useSetState();
    const setRows = this.useSetRows();
    const disableCreate = this.useDisableCreateMode();

    return useCallback(async () => {
      let message = '';

      if (!body.creatorId) {
        message = '크리에이터를 선택하세요.';
      }

      if (message) {
        setAlert({ type: 'warning', message });
        return;
      }

      await this.service.create(body);
      await setRows();

      disableCreate();
    }, [body, setAlert, setRows, disableCreate]);
  }

  public useOnClickUpdate(body: UpdateStickiesEventDto) {
    const id = this.useUpdateTarget();
    const setRows = this.useSetRows();
    const disableUpdate = this.useDisableUpdate();

    return useCallback(async () => {
      if (!id) {
        return;
      }

      await this.service.update(id, body);
      await setRows();

      disableUpdate();
    }, [id, body, setRows, disableUpdate]);
  }

  public useOnClickDeleteHandler(): () => Promise<void> {
    const id = this.useDeleteTarget();
    const setRows = this.useSetRows();
    const disableDelete = this.useDisableDelete();

    return useCallback(async () => {
      if (!id) {
        return;
      }

      await this.service.delete(id);
      await setRows();

      disableDelete();
    }, [id, setRows, disableDelete]);
  }
}

export const stickiesEventListStore = StickiesEventListStore.of();
