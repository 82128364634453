export enum UserOAuthPlatform {
  TWITCH = 'twitch',
  GOOGLE = 'google',
}

export enum UserStatus {
  ACTIVE = 'active',
  BLOCK = 'block',
  WITHDRAW = 'withdraw',
}

export enum UserKeywordOption {
  ID = 'id',
  OAUTH_ID = 'oauth_id',
  NICKNAME = 'nickname',
  EMAIL = 'email',
}

export enum UserApiError {
  NOT_FOUND_ERROR = 'NotFoundError',
}
