export enum PageAccess {
  Public = 'public',
  Private = 'private',
  Redirect = 'redirect',
}

export enum PagePath {
  All = '*',
  Unauthorized = '/401',
  Forbidden = '/403',
  Home = '/',
  SignIn = '/signin',
  LiveStream = '/live',
  IAM = '/iam',
  Service = '/service',
  ServiceSetting = '/service/setting',
  ServiceMaintenance = '/service/maintenance',
  Asset = '/asset',
  AssetSticker = '/asset/stickers',
  AssetNoti = '/asset/sounds',
  AssetTts = '/asset/tts',
  Search = '/search',
  SearchUser = '/search/user',
  SearchDonation = '/search/donation',
  SearchPayment = '/search/payment',
  SearchPayout = '/search/payout',
  Stickies = '/stickies',
  StickiesItem = '/stickies/items',
  StickiesCharge = '/stickies/charge',
  Promotion = '/promotion',
  PromotionFreeCredit = '/promotion/free-credits',
}
