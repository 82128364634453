import { FC } from 'react';
import { ConfirmModalProps } from './types';
import { Modal } from 'reactstrap';

export const ConfirmModal: FC<ConfirmModalProps> = ({
  open,
  title,
  primary,
  summary,
  doneText,
  closeText,
  onDone,
  onClose,
  ...props
}) => {
  return (
    <Modal isOpen={open} toggle={onClose} centered {...props}>
      <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">
          {title}
        </h5>
        <button type="button" className="btn-close" aria-label="Close" onClick={onClose} />
      </div>

      <div className="modal-body text-center">
        <i className="mdi mdi-alert-circle-outline" style={{ fontSize: '5em', color: 'orange' }} />
        <p>
          {primary}
          <br />
          {summary}
        </p>
      </div>

      <div className="modal-footer">
        <button type="button" className="btn btn-primary" onClick={onDone}>
          {doneText || '완료'}
        </button>
        <button type="button" className="btn btn-light" onClick={onClose}>
          {closeText || '취소'}
        </button>
      </div>
    </Modal>
  );
};
