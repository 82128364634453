export enum Envs {
  Local = 'local',
  Development = 'development',
  Staging = 'staging',
  Production = 'production',
}

export enum AwsS3Bucket {
  Profile = 'user-profile-image',
  NotiSounds = 'noti-sounds',
  DonationImage = 'donation/images',
  DonationThumb = 'donation/thumbs',
  DonationTts = 'donation/tts',
  TtsImage = 'tts/images',
  TtsSample = 'tts/samples',
  StickerCategory = 'sticker/categories',
  StickerThumb = 'sticker/thumbs',
  StickerImage = 'sticker/images',
}
