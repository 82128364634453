import { ApiService, ListQueryApiService } from '@/core';
import {
  ChangeStickiesStatusEventDto,
  CreateStickiesEventDto,
  StickiesEventListQueryDto,
  StickiesEventHistoryListDto,
  StickiesEventListDto,
  UpdateStickiesEventDto,
  StickiesEventDto,
} from './types';

import { StickiesEventApiException } from './stickies-event-api.exception';

export class StickiesEventApiService extends ApiService implements ListQueryApiService<StickiesEventDto> {
  public static of() {
    return new StickiesEventApiService('stickies-event');
  }

  async getRowsAndStats(query: StickiesEventListQueryDto): Promise<StickiesEventListDto> {
    return this.api.get(this.url(), { params: query });
  }

  async getHistoryList(id: number): Promise<StickiesEventHistoryListDto> {
    return this.api.get(this.url(id), {
      errorAlert: (e) => StickiesEventApiException.of(e).message,
    });
  }

  async create(body: CreateStickiesEventDto): Promise<void> {
    return this.api.post(this.url(), body, {
      successAlert: () => '스티키 지급 이벤트 항목이 등록되었습니다.',
      errorAlert: (e) => StickiesEventApiException.of(e).message,
    });
  }

  async update(id: number, body: UpdateStickiesEventDto): Promise<void> {
    return this.api.patch(this.url(id), body, {
      successAlert: () => '스티키 지급 이벤트 항목이 수정되었습니다.',
      errorAlert: (e) => StickiesEventApiException.of(e).message,
    });
  }

  async changeStatus(id: number, body: ChangeStickiesStatusEventDto): Promise<void> {
    return this.api.patch(this.url(id, 'activate'), body, {
      successAlert: () => '스티키 지급 이벤트 활성 상태가 변경되었습니다.',
      errorAlert: (e) => StickiesEventApiException.of(e).message,
    });
  }

  async delete(id: number): Promise<void> {
    return this.api.delete(this.url(id), {
      successAlert: () => '스티키 지급 이벤트 항목이 삭제되었습니다.',
      errorAlert: (e) => StickiesEventApiException.of(e).message,
    });
  }
}

export const stickiesEventApiService = StickiesEventApiService.of();
