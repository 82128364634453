import { Store } from '@/core';
import { PayoutAccountApiService } from '@/service';

export class PayoutAccountStore extends Store<{}> {
  private static instance: PayoutAccountStore;

  public static of(): PayoutAccountStore {
    if (!this.instance) {
      this.instance = new PayoutAccountStore({}, PayoutAccountApiService.of());
    }

    return this.instance;
  }
}
