import { DragDropTableBodyProps } from './types';

export function DragDropTableBody<D extends object>({
  tableBodyProps,
  rows,
  columns,
  prepareRow,
  emptyText,
  onClickRowHandler,
}: DragDropTableBodyProps<D>) {
  const draggable = rows.length > 0;

  return (
    <tbody {...tableBodyProps} draggable={draggable}>
      {!draggable ? (
        <tr>
          <td align="center" colSpan={columns.length}>
            {emptyText}
          </td>
        </tr>
      ) : (
        rows.map((row) => {
          prepareRow(row);

          const id = ['dragdrop', 'row', row.id].join('-');
          const rowProps = row.getRowProps({ style: { cursor: 'pointer' } });
          const onClickRow = onClickRowHandler ? onClickRowHandler(row.original) : undefined;

          return (
            <tr
              {...rowProps}
              id={id}
              onClick={(e) => {
                if (!onClickRow) {
                  return;
                }

                if (e.target instanceof HTMLTableCellElement || e.target instanceof HTMLImageElement) {
                  onClickRow(e);
                }
              }}
            >
              {row.cells.map((cell) => {
                const cellProps = cell.getCellProps({ className: 'align-middle py-1 px-2 text-center' });

                return <td {...cellProps}>{cell.render('Cell')}</td>;
              })}
            </tr>
          );
        })
      )}
    </tbody>
  );
}
