import { FC } from 'react';
import { HeaderPasswordModalProps } from './types';

export const HeaderPasswordModalButton: FC<Pick<HeaderPasswordModalProps, 'onToggle'>> = ({ onToggle }) => {
  return (
    <div className="modal-header">
      <h5 className="modal-title">비밀번호 변경</h5>
      <button className="btn-close" onClick={onToggle} />
    </div>
  );
};
