import { io } from 'socket.io-client';
import { Config } from '../config';

export class WsConnect {
  public static of(nsp?: string) {
    return io([new Config().WS_URL, nsp || ''].join('/'), {
      transports: ['websocket'],
      reconnection: true,
      autoConnect: false,
    });
  }
}
