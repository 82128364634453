import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { rootAlertStore } from '../root';
import { ContentModalImageProps } from './types';
import { fileService } from '@/service';
import { Input, Row } from 'reactstrap';

export function ContentModalImage({ label, src, name, regExp, onChangeImage }: ContentModalImageProps) {
  const setAlert = rootAlertStore.useSetState();

  const [url, setUrl] = useState<string>('');
  const [type, setType] = useState<'image' | 'video'>('image');

  useEffect(() => {
    if (src === undefined) {
      return;
    }

    setType(src.endsWith('.webm') ? 'video' : 'image');
  }, [src]);

  const onChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files;

      if (!files) {
        return;
      }

      const file = files[0];
      const message = regExp.validate(file.name);

      if (message) {
        return setAlert({
          type: 'danger',
          message,
        });
      }

      const [url, src] = await fileService.toBase64(file);

      if (file.type.startsWith('video')) {
        setType('video');
      }

      if (file.type.startsWith('image')) {
        setType('image');
      }

      setUrl(url);
      onChangeImage(name, src);
    },
    [name, regExp, setType, setUrl, onChangeImage],
  );

  return (
    <div className="form-group mt-3 mb-4">
      {label && <label>{label}</label>}
      <Input type="file" className="form-control" onChange={onChange} />
      <Row style={{ display: 'flex', justifyContent: 'center' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: 250,
            marginTop: 20,
          }}
        >
          {(url || src) &&
            (type === 'image' ? (
              <img className="rounded bg-light" alt="" src={url || src} width={250} />
            ) : (
              <video className="rounded bg-light" src={url || src} autoPlay controls width={250} />
            ))}
        </div>
      </Row>
    </div>
  );
}
