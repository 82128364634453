import { AccordionAmountRangeGroupProps } from './types';
import { AccordionSelect } from './accordion-select';
import { Input } from 'reactstrap';
import { Fragment } from 'react';

export function AccordionAmountRangeByOptionGroup({
  title,
  inputProps,
  ...selectProps
}: AccordionAmountRangeGroupProps) {
  return (
    <div className="mb-4" style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
      <h5 className="font-size-14" style={{ margin: '0px 20px 0px 0px', minWidth: '100px' }}>
        {title}
      </h5>
      <AccordionSelect {...selectProps} />
      <div style={{marginLeft: '10px'}} />
      {inputProps.map(({ startText, endText, value, ...props }) => (
        <Fragment key={['amount-input', props.name].join('-')}>
          {startText && <div style={{ margin: '0 5px' }}>{startText}</div>}
          <div>
            <Input {...props} value={value || ''} className="form-control" />
          </div>
          {endText && <div style={{ margin: '0 5px' }}>{endText}</div>}
        </Fragment>
      ))}
    </div>
  );
}
