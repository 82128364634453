import { Fragment, useCallback } from 'react';
import { CellProps } from 'react-table';

import { ListDialogStore } from '@/core';
import { OnChangeToggleSwitch, SimpleTableColumnType, ToggleSwitch } from '@/components';
import { RoleApiService, RoleDto, RolePolicyDto, roleApiService } from '@/service';

import { RoleStoreValueType } from './types';

export class RoleStore extends ListDialogStore<RoleDto, RoleStoreValueType, RoleApiService> {
  private useColumnHandlers() {
    const setState = this.useSetState();

    const onOpenUpdateModalHandler = useCallback(
      (row: RoleDto) => () => {
        setState((prev) => ({ ...prev, updateId: row.id }));
      },
      [setState],
    );

    const onOpenDeleteModalHandler = useCallback(
      (row: RoleDto) => () => {
        setState((prev) => ({ ...prev, deleteId: row.id }));
      },
      [setState],
    );

    return { onOpenUpdateModalHandler, onOpenDeleteModalHandler };
  }

  useColumns(): SimpleTableColumnType<RoleDto>[] {
    const { onOpenUpdateModalHandler, onOpenDeleteModalHandler } = this.useColumnHandlers();

    return [
      {
        accessor: 'id',
        Header: 'ID',
        minWidth: 40,
        maxWidth: 40,
      },
      {
        accessor: 'name',
        Header: '역할명',
        minWidth: 120,
        maxWidth: 120,
      },
      {
        accessor: 'staffs',
        Header: '인원수',
        minWidth: 50,
        maxWidth: 50,
        Cell: ({ value }) => (
          <div
            className="d-flex"
            style={{ height: 30, alignItems: 'center', justifyContent: 'center' }}
          >{`${value.length}명`}</div>
        ),
      },
      {
        id: 'actions',
        minWidth: 100,
        maxWidth: 100,
        Cell: ({ row }: CellProps<RoleDto>) => {
          if (!row.original.id) {
            return <Fragment />;
          }

          const onClickUpdate = onOpenUpdateModalHandler(row.original);
          const onClickDelete = onOpenDeleteModalHandler(row.original);

          return (
            <div>
              <button onClick={onClickUpdate}>
                <i className="bx bx-edit custom-icon" />
              </button>
              <button onClick={onClickDelete}>
                <i className="bx bx-trash custom-icon" />
              </button>
            </div>
          );
        },
      },
    ];
  }

  usePolicyColumns(
    onChangeToggleSwitch: OnChangeToggleSwitch,
  ): SimpleTableColumnType<[keyof Omit<RolePolicyDto, 'master'>, boolean]>[] {
    const policyName: Record<keyof Omit<RolePolicyDto, 'master'>, string> = {
      live: '실시간 방송 확인',
      user: '사용자 정보 관리',
      sticker: '스티커 관리',
      tts: 'TTS 관리',
      noti: '알림음 관리',
      gnb: 'GNB 알림 관리',
      setting: '서비스 설정 관리',
      banner: '배너 설정 관리',
      maintenance: '점검 알림 관리',
      donation: '후원 목록 조회',
      payout: '정산 출금 관리',
      stickies: '스티키 관리',
      promotion: '프로모션 관리',
    };

    return [
      {
        id: 'type',
        Header: '권한',
        Cell: ({ row }: CellProps<[keyof Omit<RolePolicyDto, 'master'>, boolean]>) => (
          <div className="d-flex" style={{ height: 35, alignItems: 'center', justifyContent: 'center' }}>
            {policyName[row.original[0]]}
          </div>
        ),
      },
      {
        id: 'checked',
        Header: 'OFF/ON',
        Cell: ({ row }: CellProps<[keyof Omit<RolePolicyDto, 'master'>, boolean]>) => {
          const [id, checked] = row.original;
          return <ToggleSwitch id={id} checked={checked} width={36} height={18} onChange={onChangeToggleSwitch} />;
        },
      },
    ];
  }
}

export const roleStore = new RoleStore(
  {
    rows: [],
    createMode: false,
    updateId: undefined,
    deleteId: undefined,
  },
  roleApiService,
);
