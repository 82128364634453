import { ApiService, ListQueryApiService } from '@/core/service';
import { UserDto, UserListDto, UserQueryDto, UserSearchListDto } from './types';
import { UserStatus } from './enums';
import { UserApiException } from './user-api.exception';

export class UserApiService extends ApiService implements ListQueryApiService<UserDto> {
  async search(keyword: string): Promise<UserSearchListDto> {
    return this.api.get(this.url('search'), {
      params: { keyword, limit: 15 },
    });
  }

  async getRowsAndStats(params: UserQueryDto): Promise<UserListDto> {
    return this.api.get(this.url(), { params });
  }

  async updateStatus(id: number, status: UserStatus): Promise<void> {
    return this.api.patch(
      this.url(id, 'status'),
      { status },
      {
        successAlert: () => '사용자 상태가 변경되었습니다.',
        errorAlert: (e) => new UserApiException(e).message,
      },
    );
  }
}

export const userApiService = new UserApiService('users');
