import { Dispatch, MouseEventHandler, SetStateAction, useCallback, useEffect } from 'react';
import { ListDialogStore, AwsS3Bucket, config, PagePath } from '@/core';
import {
  CreateNotiBodyDto,
  NotiApiService,
  NotiDto,
  NotiStatus,
  NotiType,
  UpdateNotiBodyDto,
  notiApiService,
  notiCategoryApiService,
} from '@/service';
import {
  DragDropEndEventHandler,
  DragDropTableColumnType,
  OnChangeToggleSwitch,
  ToggleSwitch,
  getUpdateSequenceTargets,
  rootAlertStore,
} from '@/components';
import { NotiStoreValueType } from './types';
import { notiCategoryStore } from './noti-category.store';
import { useNavigate } from 'react-router-dom';

export class NotiStore extends ListDialogStore<NotiDto, NotiStoreValueType, NotiApiService> {
  private useColumnHandlers() {
    const setAlert = rootAlertStore.useSetState();
    const setRows = this.useSetRows();
    const activeUpdate = this.useActiveUpdate();
    const activeDelete = this.useActiveDelete();

    const onChangeDefaultGeneralTypeHandler = useCallback(
      (row: NotiDto): OnChangeToggleSwitch =>
        async () => {
          await this.service.updateType(row.id, NotiType.Default, {
            finalHandler: setRows,
          });
        },
      [setRows, setAlert],
    );

    const onChangeDefaultConditionalTypeHandler = useCallback(
      (row: NotiDto): OnChangeToggleSwitch =>
        async () => {
          await this.service.updateType(row.id, NotiType.Conditional, {
            finalHandler: setRows,
          });
        },
      [setRows, setAlert],
    );

    const onChangeStatusHandler = useCallback(
      (row: NotiDto): OnChangeToggleSwitch =>
        async (checked) => {
          const status = checked ? NotiStatus.Active : NotiStatus.Disable;
          await this.service.updateStatus(row.id, status, {
            finalHandler: setRows,
          });
        },
      [setRows],
    );

    const onClickUpdateHandler = useCallback(
      (id: number): MouseEventHandler<HTMLButtonElement> => {
        return () => activeUpdate(id);
      },
      [activeUpdate],
    );

    const onClickDeleteHandler = useCallback(
      (id: number): MouseEventHandler<HTMLButtonElement> => {
        return () => activeDelete(id);
      },
      [activeDelete],
    );

    return {
      onChangeDefaultGeneralTypeHandler,
      onChangeDefaultConditionalTypeHandler,
      onChangeStatusHandler,
      onClickUpdateHandler,
      onClickDeleteHandler,
    };
  }

  useColumns(): DragDropTableColumnType<NotiDto>[] {
    const {
      onChangeDefaultGeneralTypeHandler,
      onChangeDefaultConditionalTypeHandler,
      onChangeStatusHandler,
      onClickUpdateHandler,
      onClickDeleteHandler,
    } = this.useColumnHandlers();

    return [
      {
        accessor: 'sequence',
        Header: '',
        minWidth: 20,
        maxWidth: 20,
        Cell: () => <i className="fa fa-fw fa-bars" />,
      },
      {
        accessor: 'id',
        Header: 'ID',
        minWidth: 40,
        maxWidth: 40,
      },
      {
        accessor: 'alias',
        Header: '별칭',
        minWidth: 120,
        maxWidth: 120,
      },
      {
        accessor: 'originName',
        Header: '파일명',
        minWidth: 120,
        maxWidth: 120,
      },
      {
        accessor: 'path',
        Header: '음원듣기',
        Cell: ({ value }) => <audio controls src={config.S3_URL(AwsS3Bucket.NotiSounds, value)} />,
      },
      {
        id: 'default',
        Header: '일반 기본',
        minWidth: 80,
        maxWidth: 80,
        Cell: ({ row }: { row: { original: NotiDto } }) => {
          return (
            <ToggleSwitch
              width={36}
              height={18}
              checked={row.original.type === NotiType.Default}
              disabled={row.original.type === NotiType.Default}
              onChange={onChangeDefaultGeneralTypeHandler(row.original)}
            />
          );
        },
      },
      {
        id: 'conditional',
        Header: '조건 기본',
        minWidth: 80,
        maxWidth: 80,
        Cell: ({ row }: { row: { original: NotiDto } }) => {
          return (
            <ToggleSwitch
              width={36}
              height={18}
              checked={row.original.type === NotiType.Conditional}
              disabled={row.original.type === NotiType.Conditional}
              onChange={onChangeDefaultConditionalTypeHandler(row.original)}
            />
          );
        },
      },
      {
        accessor: 'status',
        Header: '공개/비공개',
        minWidth: 80,
        maxWidth: 80,
        Cell: ({ row }: { row: { original: NotiDto } }) => {
          return (
            <ToggleSwitch
              width={36}
              height={18}
              checked={row.original.status === NotiStatus.Active}
              onChange={onChangeStatusHandler(row.original)}
            />
          );
        },
      },
      {
        id: 'actions',
        minWidth: 100,
        maxWidth: 100,
        Cell: ({ row }: { row: { original: NotiDto } }) => (
          <div>
            <button onClick={onClickUpdateHandler(row.original.id)}>
              <i className="bx fs-4 bx-pencil custom-icon" />
            </button>

            <button onClick={onClickDeleteHandler(row.original.id)}>
              <i className="bx fs-4 bx-trash text-danger custom-icon" />
            </button>
          </div>
        ),
      },
    ];
  }

  useInitRows(): void {
    const setRows = this.useSetRows();

    useEffect(() => {
      setRows();
    }, [setRows]);
  }

  useSetRows(): () => Promise<void> {
    const navigate = useNavigate();
    const categoryId = notiCategoryStore.useCurrent();
    const setState = this.useSetState();
    const setCategories = notiCategoryStore.useSetRows();

    return useCallback(async () => {
      if (!categoryId) {
        setState((prev) => ({ ...prev, rows: [] }));
        return;
      }

      const rows = await this.service.getRows(categoryId, {
        async errorHandler() {
          await setCategories();
          navigate(PagePath.AssetNoti);
        },
      });

      setState((prev) => ({ ...prev, rows }));
    }, [navigate, categoryId, setState, setCategories]);
  }

  useSetRow(id: number | undefined, setRow: Dispatch<SetStateAction<NotiDto>>): () => Promise<void> {
    const navigate = useNavigate();
    const categoryId = notiCategoryStore.useCurrent();
    const setCategories = notiCategoryStore.useSetRows();

    return useCallback(async () => {
      if (!categoryId || !id) {
        return;
      }

      await notiCategoryApiService.has(categoryId, {
        async errorHandler() {
          await setCategories();
          navigate(PagePath.AssetNoti);
        },
      });

      const row = await this.service.getRow(id, {
        async errorHandler() {
          navigate(0);
        },
      });

      setRow(row);
    }, [navigate, id, categoryId, setCategories, setRow]);
  }

  useOnClickCreateHandler(body: CreateNotiBodyDto): () => Promise<void> {
    const setRows = this.useSetRows();
    const disableCreate = this.useDisableCreateMode();

    return useCallback(async () => {
      await this.service.create(body, disableCreate);
      await setRows();

      disableCreate();
    }, [body, setRows, disableCreate]);
  }

  useOnClickUpdateHandler(id: number | undefined, body: UpdateNotiBodyDto): () => Promise<void> {
    const setRows = this.useSetRows();
    const disableUpdate = this.useDisableUpdate();

    return useCallback(async () => {
      if (!id) {
        return;
      }

      await this.service.update(id, body, disableUpdate);
      await setRows();

      disableUpdate();
    }, [id, body, setRows, disableUpdate]);
  }

  useOnUpdateSequence(): DragDropEndEventHandler {
    const rows = this.useRows();
    const setRows = this.useSetRows();

    return useCallback(
      async (startIndex, endIndex) => {
        const targets = getUpdateSequenceTargets(rows, startIndex, endIndex);

        if (targets.length === 0) {
          return;
        }

        await this.service.updateSequence(targets, {
          finalHandler: setRows,
        });
      },
      [rows, setRows],
    );
  }

  useOnClickDeleteHandler(id?: number): () => Promise<void> {
    const setRows = this.useSetRows();
    const disableDelete = this.useDisableDelete();

    return useCallback(async () => {
      if (!id) {
        return;
      }

      await this.service.delete(id);
      await setRows();

      disableDelete();
    }, [id, setRows, disableDelete]);
  }
}

export const notiStore = new NotiStore(
  {
    rows: [],
    createMode: false,
    updateId: undefined,
    deleteId: undefined,
  },
  notiApiService,
);
