export enum MaintenanceStatus {
  Wait = 'wait',
  Start = 'start',
  End = 'end',
}

export enum MaintenanceApiError {
  NotFoundMaintenance = 'NotFoundMaintenance',
  AlreadyStartMaintenance = 'AlreadyStartMaintenance',
  CannotDeleteMaintenance = 'CannotDeleteMaintenance',
  CannotUpdateMaintenance = 'CannotUpdateMaintenance',
}
