import { ApiException } from '@/core';
import { TtsVoiceApiError } from './enums';

export class TtsVoiceApiException extends ApiException {
  get message() {
    let message = this.name;

    switch (this.name) {
      case TtsVoiceApiError.NotFoundTtsVoice:
        message = 'TTS를 찾을 수 없습니다.';
        break;

      case TtsVoiceApiError.AlreadyExistTtsVoice:
        message = '이미 존재하는 별칭입니다.';
        break;

      case TtsVoiceApiError.AlreadyUsedTtsVoice:
        message = '이미 사용중인 코드입니다.';
        break;
    }

    return message;
  }
}
