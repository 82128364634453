import { FC } from 'react';
import { AccordionGroupProps } from './types';

export const AccordionCheckboxGroup: FC<AccordionGroupProps> = ({ children, title }) => {
  return (
    <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
      <h5 className="font-size-14 mb-4" style={{ minWidth: '100px', marginRight: '20px' }}>
        {title}
      </h5>
      {children}
    </div>
  );
};
