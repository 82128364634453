import { ApiException } from '@/core';
import { AuthApiError } from './enums';

export class AuthApiException extends ApiException {
  get message(): string {
    let message = this.name;

    switch (this.name) {
      case AuthApiError.Unauthorized:
        message = '아이디 또는 비밀번호를 다시 확인하세요.';
        break;

      case AuthApiError.IncorrectPassword:
        message = '현재 비밀번호가 일치하지 않습니다.';
        break;

      case AuthApiError.IncorrectNewPassword:
        message = '비밀번호 확인이 일치하지 않습니다.';
        break;
    }

    return message;
  }
}
