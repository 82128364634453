import { ApiException } from '@/core';
import { NotiCategoryApiError } from './enums';

export class NotiCategoryApiException extends ApiException {
  get message(): string {
    let message = this.name;

    switch (this.name) {
      case NotiCategoryApiError.NotFoundNotiSoundCategory:
        message = '알림음 카테고리를 찾을 수 없습니다.';
        break;

      case NotiCategoryApiError.AlreadyExistNotiSoundCategory:
        message = '이미 존재하는 알림음 카테고리입니다.';
        break;

      case NotiCategoryApiError.IncludedDefaultNotiSoundException:
        message = '기본 알림음이 포함되어 있습니다.';
        break;
    }

    return message;
  }
}
