import { Envs } from './enums';

export class Config {
  get ENV(): Envs {
    return process.env.REACT_APP_ENV as Envs;
  }

  get BASE_URL(): string {
    const url = process.env.REACT_APP_API_URL;

    if (!url) {
      throw new Error('env : not exist base url.');
    }

    return process.env.REACT_APP_API_URL as string;
  }

  get WS_URL(): string {
    const url = process.env.REACT_APP_WS_URL;

    if (!url) {
      throw new Error('env : not exist ws url.');
    }

    return url;
  }

  public S3_URL(...params: string[]): string {
    const cdn = process.env.REACT_APP_IMAGE_S3_URL;

    if (!cdn) {
      throw new Error('env : not exist s3 cdn path');
    }

    return [cdn, ...params].join('/');
  }

  public isEnv(env: Envs): boolean {
    return this.ENV === env;
  }

  public isMobile(): boolean {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  }
}
