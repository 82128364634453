import { FC, Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { PageContainerProps } from './types';
import { PageTitle } from './page.title';

export const PageContainer: FC<PageContainerProps> = ({ children, colProps, rowProps, title, fluid }) => {
  const pageTitle = title ? <PageTitle title={title} /> : null;

  return (
    <div className="page-content">
      <Container fluid={fluid}>
        <Row className="justify-content-center" {...rowProps}>
          {colProps ? (
            <Col {...colProps}>
              {pageTitle}
              {children}
            </Col>
          ) : (
            <Fragment>
              {pageTitle}
              {children}
            </Fragment>
          )}
        </Row>
      </Container>
    </div>
  );
};
