import { MouseEventHandler, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PagePath, ListDialogStore } from '@/core';
import {
  DragDropEndEventHandler,
  DragDropTableColumnType,
  DragDropTableRowClickEventHandler,
  OnChangeToggleSwitch,
  ToggleSwitch,
  getUpdateSequenceTargets,
  rootAlertStore,
} from '@/components';
import {
  CreateNotiCategoryBodyDto,
  NotiCategoryApiService,
  NotiCategoryDto,
  NotiCategoryStatus,
  UpdateNotiCategoryBodyDto,
  notiCategoryApiService,
} from '@/service';
import { NotiCategoryStoreValueType, NotiPageParams } from './types';

export class NotiCategoryStore extends ListDialogStore<
  NotiCategoryDto,
  NotiCategoryStoreValueType,
  NotiCategoryApiService
> {
  private useColumnHandlers() {
    const setRows = this.useSetRows();
    const activeUpdate = this.useActiveUpdate();
    const activeDelete = this.useActiveDelete();

    const onChangeStatusHandler = useCallback(
      (row: NotiCategoryDto): OnChangeToggleSwitch =>
        async (checked) => {
          const status = checked ? NotiCategoryStatus.Active : NotiCategoryStatus.Disable;
          await this.service.updateStatus(row.id, status, { finalHandler: setRows });
        },
      [setRows],
    );

    const onClickUpdateHandler = useCallback(
      (id: number): MouseEventHandler<HTMLButtonElement> => {
        return () => activeUpdate(id);
      },
      [activeUpdate],
    );

    const onClickDeleteHandler = useCallback(
      (id: number): MouseEventHandler<HTMLButtonElement> => {
        return () => activeDelete(id);
      },
      [activeDelete],
    );

    return { onChangeStatusHandler, onClickUpdateHandler, onClickDeleteHandler };
  }

  useColumns(): DragDropTableColumnType<NotiCategoryDto>[] {
    const { onChangeStatusHandler, onClickUpdateHandler, onClickDeleteHandler } = this.useColumnHandlers();

    return [
      {
        accessor: 'sequence',
        Header: '',
        minWidth: 20,
        maxWidth: 20,
        Cell: () => <i className="fa fa-fw fa-bars" />,
      },
      {
        accessor: 'id',
        Header: 'ID',
        minWidth: 40,
        maxWidth: 40,
      },
      {
        accessor: 'name',
        Header: '이름',
        minWidth: 120,
        maxWidth: 120,
      },
      {
        accessor: 'status',
        Header: '공개/비공개',
        minWidth: 80,
        maxWidth: 80,
        Cell: ({ row }) => {
          return (
            <ToggleSwitch
              width={36}
              height={18}
              checked={row.original.status === NotiCategoryStatus.Active}
              onChange={onChangeStatusHandler(row.original)}
            />
          );
        },
      },
      {
        id: 'actions',
        minWidth: 100,
        maxWidth: 100,
        Cell: ({ row }: { row: { original: NotiCategoryDto } }) => (
          <div>
            <button onClick={onClickUpdateHandler(row.original.id)}>
              <i className="bx fs-4 bx-pencil custom-icon" />
            </button>

            <button onClick={onClickDeleteHandler(row.original.id)}>
              <i className="bx fs-4 bx-trash text-danger custom-icon" />
            </button>
          </div>
        ),
      },
    ];
  }

  useCurrent(): number | undefined {
    return this.useValue().currentId;
  }

  useCurrentCategory(): NotiCategoryDto | undefined {
    return this.useValue().currentCategory;
  }

  useInitCurrent() {
    const params = useParams<NotiPageParams>();
    const navigate = useNavigate();

    const [{ currentId }, setState] = this.useState();

    useEffect(() => {
      if (!params.categoryId) {
        setState((prev) => ({ ...prev, currentId: undefined, currentCategory: undefined }));
        return;
      }

      const categoryId = parseInt(params.categoryId);

      if (isNaN(categoryId)) {
        return;
      }

      this.service.has(categoryId, {
        successHandler() {
          setState((prev) => ({
            ...prev,
            currentId: categoryId,
            currentCategory: prev.rows.find(({ id }) => id === categoryId),
          }));
        },
        errorHandler() {
          const path = currentId ? [PagePath.AssetNoti, currentId].join('/') : PagePath.AssetNoti;
          navigate(path, { replace: true });
        },
      });
    }, [params, navigate, currentId, setState]);
  }

  useOnClickRowHandler(): DragDropTableRowClickEventHandler<NotiCategoryDto> {
    const navigate = useNavigate();

    return useCallback(
      (row) => {
        return () => navigate([PagePath.AssetNoti, row.id].join('/'));
      },
      [navigate],
    );
  }

  useOnClickCreateHandler(body: CreateNotiCategoryBodyDto): MouseEventHandler {
    const setAlert = rootAlertStore.useSetState();
    const setRows = this.useSetRows();
    const disableCreateMode = this.useDisableCreateMode();

    return useCallback(async () => {
      let message = '';

      if (!body.name) {
        message = '이름을 입력하세요.';
      }

      if (message) {
        setAlert({ type: 'danger', message });
        return;
      }

      await this.service.create(body);
      await setRows();

      disableCreateMode();
    }, [body, setAlert, setRows, disableCreateMode]);
  }

  useOnClickUpdateHandler(id: number | undefined, body: UpdateNotiCategoryBodyDto): () => Promise<void> {
    const setAlert = rootAlertStore.useSetState();
    const setRows = this.useSetRows();
    const disableUpdate = this.useDisableUpdate();

    return useCallback(async () => {
      if (!id) {
        return;
      }

      let message = '';

      if (!body.name) {
        message = '이름을 입력하세요.';
      }

      if (message) {
        setAlert({ type: 'danger', message });
        return;
      }

      await this.service.update(id, body);
      await setRows();

      disableUpdate();
    }, [id, body, setAlert, setRows, disableUpdate]);
  }

  useOnClickDeleteHandler(id?: number): () => Promise<void> {
    const setRows = this.useSetRows();
    const disableDelete = this.useDisableDelete();

    return useCallback(async () => {
      if (!id) {
        return;
      }

      await this.service.delete(id, {
        async finalHandler() {
          await setRows();
          disableDelete();
        },
      });
    }, [id, setRows, disableDelete]);
  }

  useOnUpdateSequence(): DragDropEndEventHandler {
    const rows = this.useRows();
    const setRows = this.useSetRows();

    return useCallback(
      async (startIndex, endIndex) => {
        const targets = getUpdateSequenceTargets(rows, startIndex, endIndex);

        if (targets.length === 0) {
          return;
        }

        await this.service.updateSequence(targets);
        await setRows();
      },
      [rows, setRows],
    );
  }
}

export const notiCategoryStore = new NotiCategoryStore(
  {
    currentId: undefined,
    rows: [],
    createMode: false,
    updateId: undefined,
    deleteId: undefined,
  },
  notiCategoryApiService,
);
