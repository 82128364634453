import { ChangeEventHandler, Dispatch, SetStateAction, useCallback } from 'react';
import {
  ContentModalImageChangeHandler,
  ContentModalSingleSelectChangeHandler,
  ContentModalSoundChangeHandler,
} from './types';

export class ContentModalEventHandler {
  useOnChangeRadio = this.useOnChangeInput;
  useOnChangeInput<D>(setState: Dispatch<SetStateAction<D>>): ChangeEventHandler<HTMLInputElement> {
    return useCallback(
      (e) => {
        const { name, value } = e.target;

        setState((prev) => ({ ...prev, [name]: value }));
      },
      [setState],
    );
  }

  useOnChangeTextArea<D>(setState: Dispatch<SetStateAction<D>>): ChangeEventHandler<HTMLTextAreaElement> {
    return useCallback(
      (e) => {
        const { name, value } = e.target;

        setState((prev) => ({ ...prev, [name]: value }));
      },
      [setState],
    );
  }

  useOnChangeImage<D>(setState: Dispatch<SetStateAction<D>>): ContentModalImageChangeHandler {
    return useCallback(
      (name, src) => {
        setState((prev) => ({ ...prev, [name]: src }));
      },
      [setState],
    );
  }

  useOnChangeSound<D>(setState: Dispatch<SetStateAction<D>>): ContentModalSoundChangeHandler {
    return useCallback(
      (name, fileName, src) => {
        setState((prev) => ({ ...prev, fileName, [name]: src }));
      },
      [setState],
    );
  }

  useOnChangeSingleSelect<D>(
    setState: Dispatch<SetStateAction<D>>,
    defaultValue: any = '',
  ): ContentModalSingleSelectChangeHandler {
    return useCallback(
      (val, meta) => {
        const name = meta.name;

        if (!name) {
          return;
        }

        setState((prev) => ({ ...prev, [name]: val ? val.value : defaultValue }));
      },
      [setState, defaultValue],
    );
  }
}

export const contentModalEventHandler = new ContentModalEventHandler();
