import { ApiService, ListApiService } from '@/core/service';
import { CreateStickerCategoryBodyDto, StickerCategoryDto, UpdateStickerCategoryBodyDto } from './types';
import { DragDropTableSequenceType } from '@/components';
import { StickerCategoryStatus } from './enums';
import { StickerCategoryApiException } from './sticker-category.exception';

export class StickerCategoryApiService extends ApiService implements ListApiService<StickerCategoryDto> {
  async getRows(): Promise<StickerCategoryDto[]> {
    return this.api.get(this.url());
  }

  async getRow(id: number): Promise<StickerCategoryDto> {
    return this.api.get(this.url(id), {
      errorAlert(e) {
        return new StickerCategoryApiException(e).message;
      },
    });
  }

  async has(id: number): Promise<void> {
    return this.api.head(this.url(id), {
      errorAlert(e) {
        return new StickerCategoryApiException(e).message;
      },
    });
  }

  async create(body: CreateStickerCategoryBodyDto): Promise<void> {
    return this.api.post(this.url(), body, {
      successAlert() {
        return '스티커 카테고리가 생성되었습니다.';
      },
      errorAlert(e) {
        return new StickerCategoryApiException(e).message;
      },
    });
  }

  async update(id: number, body: UpdateStickerCategoryBodyDto): Promise<void> {
    return this.api.patch(this.url(id), body, {
      successAlert() {
        return '스티커 카테고리가 수정되었습니다.';
      },
      errorAlert(e) {
        return new StickerCategoryApiException(e).message;
      },
    });
  }

  async delete(id: number): Promise<void> {
    return this.api.delete(this.url(id), {
      successAlert() {
        return '스티커 카테고리가 삭제되었습니다.';
      },
      errorAlert(e) {
        return new StickerCategoryApiException(e).message;
      },
    });
  }

  async updateStatus(id: number, status: StickerCategoryStatus): Promise<void> {
    return this.api.patch(
      this.url(id),
      { status },
      {
        successAlert() {
          return '스티커 카테고리의 상태가 변경되었습니다.';
        },
        errorAlert(e) {
          return new StickerCategoryApiException(e).message;
        },
      },
    );
  }

  async updateIsAdult(id: number, isAdultSticker: boolean): Promise<void> {
    return this.api.patch(
      this.url(id),
      { isAdultSticker },
      {
        successAlert() {
          return '스티커 카테고리의 18+ 여부가 변경되었습니다.';
        },
        errorAlert(e) {
          return new StickerCategoryApiException(e).message;
        },
      },
    );
  }

  async updateSequence(rows: DragDropTableSequenceType[]) {
    return this.api.patch(
      this.url('sequence'),
      { rows },
      {
        successAlert() {
          return '스티커 카테고리 순서가 변경되었습니다.';
        },
        errorAlert(e) {
          return new StickerCategoryApiException(e).message;
        },
      },
    );
  }
}

export const stickerCategoryApiService = new StickerCategoryApiService('sticker-categories');
