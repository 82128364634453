import { useCallback } from 'react';
import { Store } from '@/core';
import { AuthRolePolicyKey } from '@/service';
import { ClientStoreValueType } from './types';

export class ClientStore extends Store<ClientStoreValueType | undefined> {
  private usePolicy(): AuthRolePolicyKey[] {
    const client = this.useValue();

    if (!client) {
      return [];
    }

    return client.roles.reduce<AuthRolePolicyKey[]>((prev, role) => {
      Object.entries(role.policy).forEach(([k, v]) => {
        const key = k as AuthRolePolicyKey;
        if (v && !prev.includes(key)) {
          prev.push(key);
        }
      });

      return prev;
    }, []);
  }

  usePolicyFilter() {
    const policies = this.usePolicy();
    const isMaster = policies.includes('master');
    const target = policies.join('|');

    return useCallback(
      (item: { policies: AuthRolePolicyKey[] }) => {
        const regexp = new RegExp(item.policies.join('|'));
        return isMaster || regexp.test(target);
      },
      [policies, isMaster, target],
    );
  }
}

export const clientStore = new ClientStore(undefined, undefined);
