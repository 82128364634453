import { ApiService, ListApiService } from '@/core/service';
import { DragDropTableSequenceType } from '@/components';
import { StickerStatus } from './enums';
import { CreateStickerBodyDto, StickerDto, UpdateStickerBodyDto } from './types';
import { StickerApiException } from './sticker.exception';

export class StickerApiService extends ApiService implements ListApiService<StickerDto> {
  async getRows(categoryId: number): Promise<StickerDto[]> {
    return this.api.get(this.url('list', categoryId), {
      errorAlert(e) {
        return new StickerApiException(e).message;
      },
    });
  }

  async getRow(id: number): Promise<StickerDto> {
    return this.api.get(this.url(id), {
      errorAlert(e) {
        return new StickerApiException(e).message;
      },
    });
  }

  async create(body: CreateStickerBodyDto): Promise<void> {
    return this.api.post(this.url(), body, {
      successAlert() {
        return '스티커가 등록되었습니다.';
      },
      errorAlert(e) {
        return new StickerApiException(e).message;
      },
    });
  }

  async update(id: number, body: UpdateStickerBodyDto): Promise<void> {
    return this.api.patch(this.url(id), body, {
      successAlert() {
        return '스티커가 수정되었습니다.';
      },
      errorAlert(e) {
        return new StickerApiException(e).message;
      },
    });
  }

  async updateStatus(id: number, status: StickerStatus): Promise<void> {
    return this.api.patch(
      this.url(id),
      { status },
      {
        successAlert() {
          return '스티커 상태가 변경되었습니다.';
        },
        errorAlert(e) {
          return new StickerApiException(e).message;
        },
      },
    );
  }

  async updateSequence(rows: DragDropTableSequenceType[]) {
    return this.api.patch(
      this.url('sequence'),
      { rows },
      {
        successAlert() {
          return '스티커 순서가 변경되었습니다.';
        },
        errorAlert(e) {
          return new StickerApiException(e).message;
        },
      },
    );
  }

  async delete(id: number): Promise<void> {
    return this.api.delete(this.url(id), {
      successAlert() {
        return '스티커가 삭제되었습니다.';
      },
      errorAlert(e) {
        return new StickerApiException(e).message;
      },
    });
  }
}

export const stickerApiService = new StickerApiService('stickers');
