export class FileService {
  async toDataTransfer(src: string, fileName: string): Promise<DataTransfer> {
    return new Promise<DataTransfer>((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', src);
      xhr.responseType = 'blob';
      xhr.send();
      xhr.onload = () => {
        const file = new File([xhr.response], fileName);
        const data = new DataTransfer();
        data.items.add(file);
        resolve(data);
      };
      xhr.onerror = (e) => reject(e);
    });
  }

  async toBase64(file: File): Promise<[string, string]> {
    return new Promise<[string, string]>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const url = reader.result as string;
        const arr = url.split('base64,');
        const src = arr.slice(1, arr.length).join('');
        resolve([url, src]);
      };
      reader.onerror = (e) => reject(e);
    });
  }
}

export const fileService = new FileService();
