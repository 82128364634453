import { ApiException } from '@/core';
import { StickiesEventApiError } from './enums';

export class StickiesEventApiException extends ApiException {
  public static of(e?: any) {
    return new StickiesEventApiException(e);
  }

  get message(): string {
    let message = this.name;

    switch (this.name) {
      case StickiesEventApiError.NOT_FOUND_STICKIES_EVENT:
        message = '이벤트 항목을 찾을 수 없습니다.';
        break;

      case StickiesEventApiError.INVALID_STICKIES_EVENT_AMOUNT:
        message = '총 예산과 1회당 지급 금액을 다시 확인하세요.';
        break;

      case StickiesEventApiError.ALREADY_EXIST_STICKIES_EVENT:
        message = '해당 크리에이터의 스티키 이벤트 항목이 이미 존재합니다.';
        break;
    }

    return message;
  }
}
