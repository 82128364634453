import { useCallback } from 'react';
import { OnChangeDateRangeHandler } from './types';
import { SetterOrUpdater } from 'recoil';
import { ListQueryDto, LuxonFormat } from '@/core';
import { DateTime } from 'luxon';

export class DatePickerService {
  toJSDate(datetime: string | undefined, format: LuxonFormat): Date | null {
    if (!datetime) {
      return null;
    }

    return DateTime.fromFormat(datetime, format).toJSDate();
  }

  onChangeRangeHandler<T extends { query: ListQueryDto }>(
    setState: SetterOrUpdater<T>,
    format: LuxonFormat,
  ): OnChangeDateRangeHandler {
    return useCallback(
      (val) => {
        if (!Array.isArray(val)) {
          return setState((prev) => {
            if (!prev.query.startDate && !prev.query.endDate) {
              return prev;
            }

            return {
              ...prev,
              query: {
                ...prev.query,
                startDate: undefined,
                endDate: undefined,
              },
            };
          });
        }

        const startDate = DateTime.fromJSDate(val[0]).toFormat(format);
        const endDate = DateTime.fromJSDate(val[1]).toFormat(format);

        setState((prev) => ({
          ...prev,
          query: { ...prev.query, startDate, endDate },
        }));
      },
      [setState, format],
    );
  }
}

export const datePickerService = new DatePickerService();
