export enum PayoutApiError {
  CannotPayoutStatus = 'CannotPayoutStatus',
  CannotPayoutDate = 'CannotPayoutDate',
  NotFoundPayout = 'NotFoundPayout',
  NotFoundPaypalAccount = 'NotFoundPaypalAccount',
  NotExistCanPayouts = 'NotExistCanPayouts',
}

export enum PayoutPlatform {
  PAYPAL = 'paypal',
}

export enum PayoutStatus {
  WAITING = 'waiting',
  TRANSFERRED = 'transferred',
  PENDING = 'pending',
  PROCESSING = 'processing',
  CANCELED = 'canceled',
  FAILED = 'failed',
  UNCLAIMED = 'unclaimed',
  RETURNED = 'returned',
  ONHOLD = 'onhold',
  BLOCKED = 'blocked',
  REFUNDED = 'refunded',
  REVERSED = 'reversed',
}

export enum PayoutPaypalStatus {
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  SUCCESS = 'SUCCESS',
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
  UNCLAIMED = 'UNCLAIMED',
  RETURNED = 'RETURNED',
  ONHOLD = 'ONHOLD',
  BLOCKED = 'BLOCKED',
  REFUNDED = 'REFUNDED',
  REVERSED = 'REVERSED',
}

export enum PayoutAmountRangeOption {
  GROSS = 'gross',
  NET = 'net',
}

export enum PayoutKeywordOption {
  ID = 'id',
  USER_ID = 'userId',
  NICKNAME = 'nickname',
  EMAIL = 'email',
  PAYPAL_ID = 'paypalId',
}
