import { AwsS3Bucket, ISOToString, ListQueryStore, LuxonFormat, SortOrder, config } from '@/core';
import {
  UserApiService,
  UserDto,
  UserQueryDto,
  userApiService,
  UserKeywordOption,
  UserStatus,
  userService,
} from '@/service';

import { UserStoreValueType } from './types';
import { AccordionSelectOption, PaginationTableColumnType } from '@/components';
import { profileImage } from '@/assets/images';

import { DateTime } from 'luxon';
import { useCallback, useState } from 'react';
import { CellProps } from 'react-table';

export class UserStore extends ListQueryStore<UserDto, UserStoreValueType, UserApiService> {
  useResetQuery(): () => void {
    const setState = this.useSetState();

    return useCallback(() => {
      setState((prev) => ({
        ...prev,
        query: {
          offset: prev.query.offset,
          limit: prev.query.limit,
          sortOrder: prev.query.sortOrder,
          startDate: prev.query.startDate,
          endDate: prev.query.endDate,
        },
      }));
    }, [setState]);
  }

  useSetRows(): () => Promise<void> {
    return super.useSetRows<UserQueryDto>('offset', 'limit', 'startDate', 'endDate', 'sortOrder');
  }

  useSetRowsBySearch(): () => Promise<void> {
    return super.useSetRows<UserQueryDto>();
  }

  useKeywordOptions(): AccordionSelectOption[] {
    return [
      {
        label: 'All',
        value: undefined,
      },
      {
        label: 'ID',
        value: UserKeywordOption.ID,
      },
      {
        label: 'OAuth ID',
        value: UserKeywordOption.OAUTH_ID,
      },
      {
        label: 'Nickname',
        value: UserKeywordOption.NICKNAME,
      },
      {
        label: 'Email',
        value: UserKeywordOption.EMAIL,
      },
    ];
  }

  useColumns(): PaginationTableColumnType<UserDto>[] {
    return [
      {
        accessor: 'id',
        Header: 'ID',
        width: 40,
        minWidth: 40,
        maxWidth: 40,
      },
      {
        accessor: 'platform',
        Header: 'Platform',
        width: 50,
        minWidth: 50,
        maxWidth: 50,
        Cell: ({ value }) => <>{userService.getTextByOAuthPlatform(value)}</>,
      },
      {
        accessor: 'imagePath',
        Header: 'Profile Image',
        minWidth: 80,
        maxWidth: 80,
        Cell: ({ value }) => (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '5px 0' }}>
            <div
              style={{
                height: 35,
                width: 35,
                borderRadius: '50%',
                background: `url(${value ? config.S3_URL(AwsS3Bucket.Profile, value) : profileImage})`,
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundPositionX: 'center',
              }}
            />
          </div>
        ),
      },
      {
        accessor: 'nickname',
        Header: 'Nickname',
        minWidth: 80,
        maxWidth: 80,
      },
      {
        accessor: 'email',
        Header: 'Email',
        minWidth: 120,
        maxWidth: 120,
      },
      {
        accessor: 'stickies',
        Header: 'Stickies',
        minWidth: 60,
        maxWidth: 60,
        Cell: ({ value }) => <>{value.toFixed(2)}</>,
      },
      {
        id: 'status',
        Header: 'Status',
        minWidth: 100,
        maxWidth: 100,
        Cell: ({ row }: CellProps<UserDto>) => {
          const status = row.original.status;

          const isActive = status === UserStatus.ACTIVE;
          const isBlock = status === UserStatus.BLOCK;
          const isWithdraw = status === UserStatus.WITHDRAW;

          const [className, setClassName] = useState<string>(userService.getClassNameByStatus(status));
          const [text, setText] = useState<string>(userService.getTextByStatus(status));
          const setActiveUpdate = this.useActiveUpdate();

          const onButtonOver = useCallback(() => {
            if (isActive) {
              setClassName(userService.getClassNameByStatus(UserStatus.BLOCK));
              setText(userService.getTextByStatus(UserStatus.BLOCK));
              return;
            }

            if (isBlock) {
              setClassName(userService.getClassNameByStatus(UserStatus.ACTIVE));
              setText(userService.getTextByStatus(UserStatus.ACTIVE));
              return;
            }

            if (isWithdraw) {
              setClassName(userService.getClassNameByStatus(UserStatus.ACTIVE));
              setText('Restore');
              return;
            }
          }, [isActive, isBlock, setText, setClassName]);

          const onButtonLeave = useCallback(() => {
            setText(userService.getTextByStatus(status));
            setClassName(userService.getClassNameByStatus(status));
          }, [status, setText, setClassName]);

          return (
            <button
              className={['btn', className].join(' ')}
              onClick={() => setActiveUpdate(row.original.id)}
              onMouseOver={onButtonOver}
              onMouseLeave={onButtonLeave}
            >
              {text}
            </button>
          );
        },
      },
      {
        accessor: 'createdAt',
        Header: 'Signup Date',
        minWidth: 100,
        maxWidth: 100,
        Cell: ({ value }) => <>{value ? ISOToString(value, LuxonFormat.Day) : '-'}</>,
      },
    ];
  }
}

export const userStore = new UserStore(
  {
    total: 0,
    rows: [],
    query: {
      offset: 0,
      limit: 10,
      sortOrder: SortOrder.DESC,
      startDate: DateTime.local().startOf('month').toFormat(LuxonFormat.Day),
      endDate: DateTime.local().endOf('month').toFormat(LuxonFormat.Day),
    },
  },
  userApiService,
);
