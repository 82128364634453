import { FC, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { cookie, PagePath } from '@/core';
import { HeaderUserButtonProps } from './types';
import { rootAlertStore } from '@/components/root';
import { clientStore } from '@/store';

const classes = {
  div: 'd-flex align-items-center text-nowrap',
  button: 'w-100 mx-2',
  icon: {
    password: 'bx bx-lock font-size-16 me-1 align-middle',
    signout: 'bx bx-power-off font-size-16 align-middle me-1 text-danger',
  },
  link: 'dropdown-item mx-2',
};

export const HeaderClientButtons: FC<HeaderUserButtonProps> = ({ onToggle }) => {
  const resetClient = clientStore.useResetState();
  const setRootAlert = rootAlertStore.useSetState();

  const onClick = useCallback(() => {
    cookie.removeTokens();
    resetClient;
    setRootAlert({
      type: 'info',
      message: '로그아웃 되었습니다.',
    });
  }, [resetClient, setRootAlert]);

  return (
    <div className={classes.div}>
      <button className={classes.button} onClick={onToggle}>
        <i className={classes.icon.password} />
        비밀번호 변경
      </button>
      <Link to={PagePath.SignIn} className={classes.link} onClick={onClick}>
        <i className={classes.icon.signout} />
        로그아웃
      </Link>
    </div>
  );
};
