import { ApiException } from '@/core';
import { StickiesChargeApiError } from './enums';

export class StickiesChargeApiException extends ApiException {
  public static of(e?: any) {
    return new StickiesChargeApiException(e);
  }

  get message(): string {
    let message = this.name;

    switch (this.name) {
      case StickiesChargeApiError.NOT_FOUND_USER:
        message = '사용자를 찾을 수 없습니다.';
        break;

      case StickiesChargeApiError.INVALID_STICKIES_CHARGE_AMOUNT:
        message = '$1 ~ $1,000 범위의 금액을 입력하세요.';
        break;
    }

    return message;
  }
}
