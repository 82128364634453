import { useCallback } from 'react';
import { CellProps } from 'react-table';

import { ListDialogStore } from '@/core';
import { SimpleTableColumnType } from '@/components';
import { StaffApiService, StaffDto, staffApiService } from '@/service';

import { StaffStoreValueType } from './types';

export class StaffStore extends ListDialogStore<StaffDto, StaffStoreValueType, StaffApiService> {
  private useColumnHandlers() {
    const setState = this.useSetState();

    const onOpenDeleteModalHandler = useCallback(
      (row: StaffDto) => () => {
        setState((prev) => ({ ...prev, deleteId: row.id }));
      },
      [setState],
    );

    return { onOpenDeleteModalHandler };
  }

  useColumns(): SimpleTableColumnType<StaffDto>[] {
    const { onOpenDeleteModalHandler } = this.useColumnHandlers();

    return [
      {
        accessor: 'id',
        Header: 'ID',
        minWidth: 40,
        maxWidth: 40,
      },
      {
        accessor: 'name',
        Header: '이름',
        minWidth: 100,
        maxWidth: 100,
        Cell: ({ value }) => (
          <div className="d-flex" style={{ height: 30, alignItems: 'center', justifyContent: 'center' }}>
            {value}
          </div>
        ),
      },
      {
        accessor: 'roles',
        Header: '역할',
        minWidth: 100,
        maxWidth: 100,
        Cell: ({ value }) => {
          if (value.length === 0) {
            return <span className="btn btn-light btn-sm mx-1">역할없음</span>;
          }

          return (
            <>
              {value.map((role) => (
                <span key={[role.id, role.name].join('-')} className="btn btn-light btn-sm mx-1">
                  {role.name}
                </span>
              ))}
            </>
          );
        },
      },
      {
        id: 'actions',
        minWidth: 100,
        maxWidth: 100,
        Cell: ({ row }: CellProps<StaffDto>) => {
          const onClickOpen = onOpenDeleteModalHandler(row.original);

          return (
            <div>
              <button onClick={onClickOpen}>
                <i className="bx bx-trash custom-icon" />
              </button>
            </div>
          );
        },
      },
    ];
  }
}

export const staffStore = new StaffStore(
  {
    rows: [],
    createMode: false,
    updateId: undefined,
    deleteId: undefined,
  },
  staffApiService,
);
