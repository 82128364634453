import { DragDropTableSequenceType } from './types';

export const getUpdateSequenceTargets = <T extends DragDropTableSequenceType>(
  rows: T[],
  startIndex: number,
  endIndex: number,
): DragDropTableSequenceType[] => {
  if (startIndex < endIndex) {
    const targets = rows.slice(startIndex, endIndex + 1);

    const start = targets[0];
    const end = targets[targets.length - 1];

    return targets.map(({ id, sequence }) => ({
      id,
      sequence: sequence === start.sequence ? end.sequence : sequence - 1,
    }));
  }

  if (startIndex > endIndex) {
    const targets = rows.slice(endIndex, startIndex + 1);

    const end = targets[0];
    const start = targets[targets.length - 1];

    return targets.map(({ id, sequence }) => ({
      id,
      sequence: sequence === start.sequence ? end.sequence : sequence + 1,
    }));
  }

  return [];
};
