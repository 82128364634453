import { FC, useEffect } from 'react';

import { webhookApiService } from '@/service';

export const ErrorComponent: FC<{ error: Error }> = ({ error }) => {
  useEffect(() => {
    if (error) {
      webhookApiService.reportError(error);
    }
  }, [error]);

  return <div>ERROR</div>;
};
