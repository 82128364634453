import { ApiService } from '@/core/service';
import { DragDropTableSequenceType } from '@/components';
import { NotiCategoryStatus } from './enums';
import { CreateNotiCategoryBodyDto, NotiCategoryDto, UpdateNotiCategoryBodyDto } from './types';
import { NotiCategoryApiException } from './noti-category.exception';
import { ApiAlertConfig, ApiHandlerConfig } from '@/core';

export class NotiCategoryApiService extends ApiService {
  async getRows(): Promise<NotiCategoryDto[]> {
    return this.api.get(this.url());
  }

  async getRow(id: number): Promise<NotiCategoryDto> {
    return this.api.get(this.url(id), {
      errorAlert(e) {
        return new NotiCategoryApiException(e).message;
      },
    });
  }

  async has(id: number, handlerConfig: ApiHandlerConfig): Promise<void> {
    const alertConfig: ApiAlertConfig = {
      errorAlert(e) {
        return new NotiCategoryApiException(e).message;
      },
    };

    return this.api.head(this.url(id), { ...alertConfig, ...handlerConfig });
  }

  async create(body: CreateNotiCategoryBodyDto): Promise<void> {
    const alertConfig: ApiAlertConfig = {
      successAlert() {
        return '알림음 카테고리가 생성되었습니다.';
      },
      errorAlert(e) {
        return new NotiCategoryApiException(e).message;
      },
    };

    return this.api.post(this.url(), body, alertConfig);
  }

  async update(id: number, body: UpdateNotiCategoryBodyDto): Promise<void> {
    const alertConfig: ApiAlertConfig = {
      successAlert() {
        return '알림음 카테고리가 수정되었습니다.';
      },
      errorAlert(e) {
        return new NotiCategoryApiException(e).message;
      },
    };

    return this.api.patch(this.url(id), body, alertConfig);
  }

  async delete(id: number, handlerConfig: ApiHandlerConfig): Promise<void> {
    const alertConfig: ApiAlertConfig = {
      successAlert() {
        return '알림음 카테고리가 삭제되었습니다.';
      },
      errorAlert(e) {
        return new NotiCategoryApiException(e).message;
      },
    };

    return this.api.delete(this.url(id), { ...alertConfig, ...handlerConfig });
  }

  async updateStatus(id: number, status: NotiCategoryStatus, handlerConfig: ApiHandlerConfig): Promise<void> {
    const alertConfig: ApiAlertConfig = {
      successAlert() {
        return '알림음 카테고리의 상태가 변경되었습니다.';
      },
      errorAlert(e) {
        return new NotiCategoryApiException(e).message;
      },
    };

    return this.api.patch(this.url(id), { status }, { ...alertConfig, ...handlerConfig });
  }

  async updateSequence(rows: DragDropTableSequenceType[]) {
    return this.api.patch(
      this.url('sequence'),
      { rows },
      {
        successAlert() {
          return '알림음 카테고리 순서가 변경되었습니다.';
        },
        errorAlert(e) {
          return new NotiCategoryApiException(e).message;
        },
      },
    );
  }
}

export const notiCategoryApiService = new NotiCategoryApiService('noti-sound-categories');
