import { v4 } from 'uuid';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Input } from 'reactstrap';
import { fileService } from '@/service';
import { ContentModalSoundProps } from './types';
import { rootAlertStore } from '../root';

export function ContentModalSound({ label, name, regExp, onChangeSound, src, fileName }: ContentModalSoundProps) {
  const id = useMemo(() => v4(), []);
  const setAlert = rootAlertStore.useSetState();

  const [url, setUrl] = useState<string>('');

  useEffect(() => {
    const input = document.getElementById(id) as HTMLInputElement;

    if (!input || !src || !fileName) {
      return;
    }

    fileService.toDataTransfer(src, fileName).then((dataTransfer) => {
      input.files = dataTransfer.files;
    });
  }, [id, src, fileName]);

  const onChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files;

      if (!files) {
        return;
      }

      const file = files[0];
      const message = regExp.validate(file.name);

      if (message) {
        return setAlert({
          type: 'danger',
          message,
        });
      }

      const [url, src] = await fileService.toBase64(file);

      setUrl(url);
      onChangeSound(name, file.name, src);
    },
    [name, regExp, setUrl, onChangeSound],
  );

  return (
    <>
      <div className="form-group mt-3 mb-4">
        {label && <label>{label}</label>}
        <Input id={id} type="file" className="form-control" onChange={onChange} />
      </div>
      <div className="form-group mb-4">{(url || src) && <audio controls src={url || src} className="w-100" />}</div>
    </>
  );
}
