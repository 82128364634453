import { FC } from 'react';
import { AccordionCheckboxProps } from './types';

export const AccordionCheckbox: FC<AccordionCheckboxProps> = ({ id, label, ...props }) => {
  return (
    <div className="form-check mb-4" style={{ marginRight: '10px' }}>
      <input className="form-check-input" type="checkbox" id={id} value={id} {...props} style={{ cursor: 'pointer' }} />
      <label className="form-check-label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
};
