import { ApiException } from '@/core';
import { RoleApiError } from './enums';

export class RoleApiException extends ApiException {
  get message(): string {
    let message = this.name;

    switch (this.name) {
      case RoleApiError.AlreadyExistRole:
        message = '이미 존재하는 역할입니다.';
        break;

      case RoleApiError.NotFoundRole:
        message = '역할을 찾을 수 없습니다.';
        break;

      case RoleApiError.NotFoundStaff:
        message = '계정을 찾을 수 없습니다.';
        break;
    }

    return message;
  }
}
