import { ApiService } from '@/core/service';
import { AuthSignInBodyDto, AuthSignInDto, AuthDto, AuthUpdatePasswordBodyDto } from './types';
import { AuthApiException } from './auth.exception';

export class AuthApiService extends ApiService {
  async auth(): Promise<AuthDto> {
    return this.api.get<AuthDto>(this.url());
  }

  async signIn(account: string, password: string): Promise<AuthSignInDto> {
    return this.api.post<AuthSignInBodyDto, AuthSignInDto>(
      this.url('sign'),
      { account, password },
      {
        successAlert() {
          return '인증에 성공하였습니다.';
        },
        errorAlert(e) {
          return new AuthApiException(e).message;
        },
      },
    );
  }

  async updatePassword(body: AuthUpdatePasswordBodyDto): Promise<void> {
    return this.api.patch(this.url('password'), body, {
      successAlert() {
        return '비밀번호가 변경되었습니다.';
      },
      errorAlert(e) {
        return new AuthApiException(e).message;
      },
    });
  }
}

export const authApiService = new AuthApiService('auth');
