import { AccordionSelectGroupProps } from './types';
import { AccordionSelect } from './accordion-select';

export function AccordionSelectGroup({ title, ...selectProps }: AccordionSelectGroupProps) {
  return (
    <div className="mb-4" style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
      <h5 className="font-size-14" style={{ margin: '0px 20px 0px 0px', minWidth: '100px' }}>
        {title}
      </h5>
      <AccordionSelect {...selectProps} />
    </div>
  );
}
