import { AccordionSelectOption } from '@/components';
import { ContentTemplateCode, DonationMessageType } from './enums';
import { DonationDto } from './types';

export class DonationService {
  getTypes(donation: DonationDto): string[] {
    const types: string[] = [];

    if (donation.message) {
      switch (donation.message.type) {
        case DonationMessageType.NOTI:
          types.push('Message');
          break;

        case DonationMessageType.SIMPLE:
          types.push('Message Only');
          break;
      }
    }

    if (donation.sticker) {
      types.push('Sticker');
    }

    if (donation.image) {
      types.push('Image');
    }

    if (donation.contentTemplate.code === ContentTemplateCode.WHEEL) {
      types.push('Wheel')
    }

    return types;
  }

  getRadioFilterOptions() {
    return [
      {
        label: 'All',
        value: undefined,
      },
      {
        label: 'Y',
        value: true,
      },
      {
        label: 'N',
        value: false,
      },
    ];
  }

  getContentTemplateFilterOptions(): AccordionSelectOption[] {
    return [
      {
        label: 'None',
        value: undefined,
      },
      {
        label: 'Message Only',
        value: ContentTemplateCode.SIMPLE,
      },
    ];
  }
}

export const donationService = new DonationService();
