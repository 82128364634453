import { FC, Fragment } from 'react';
import { Spinner } from 'reactstrap';
import { rootLoaderStore } from './root.loader.store';
import { RootLoaderProps } from './types';

export const RootLoader: FC<RootLoaderProps> = ({ isFallback }) => {
  const loader = rootLoaderStore.useValue();

  const show = loader || isFallback;

  return (
    <Fragment>
      {show && (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            position: 'absolute',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            zIndex: 999999,
          }}
        >
          <Spinner
            color="primary"
            style={{
              height: '3rem',
              width: '3rem',
            }}
          />
        </div>
      )}
    </Fragment>
  );
};
