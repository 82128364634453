import { ApiService, ListQueryApiService } from '@/core';
import { DonationDto, DonationListDto, DonationQueryDto } from './types';
import { DonationApiException } from './donation.exception';

export class DonationApiService extends ApiService implements ListQueryApiService<DonationDto> {
  async getRowsAndStats(params: DonationQueryDto): Promise<DonationListDto> {
    return this.api.get(this.url(), { params });
  }

  async updateImageBanStatus(id: number, isBan: boolean): Promise<void> {
    return this.api.patch(
      this.url('image', id),
      { isBan },
      {
        successAlert() {
          return `${isBan ? '비공개' : '공개'} 상태로 변경되었습니다.`;
        },
        errorAlert(e) {
          return new DonationApiException(e).message;
        },
      },
    );
  }
}

export const donationApiService = new DonationApiService('donations');
