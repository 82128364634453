export enum StickerCategoryStatus {
  Active = 'active',
  Disable = 'disable',
}

export enum StickerType {
  Generic = 'generic',
  Super = 'super',
}

export enum StickerStatus {
  Active = 'active',
  Disable = 'disable',
}

export enum StickerCategoryApiError {
  AlreadyExistStickerCategory = 'AlreadyExistStickerCategory',
  NotFoundStickerCategory = 'NotFoundStickerCategory',
}

export enum StickerApiError {
  NotFoundStickerCategory = 'NotFoundStickerCategory',
  NotFoundSticker = 'NotFoundSticker',
}
