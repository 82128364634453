import { Col, Row } from 'reactstrap';
import { PaginationTableLimitSelectorProps } from './types';
import { ChangeEventHandler, useCallback } from 'react';

export function PaginationTableLimitSelector<D extends object>({
  limit,
  setState,
}: PaginationTableLimitSelectorProps<D>) {
  const onChange: ChangeEventHandler<HTMLSelectElement> = useCallback(
    (e) => {
      const limit = parseInt(e.target.value);

      if (isNaN(limit)) {
        return;
      }

      setState((prev) => ({ ...prev, query: { ...prev.query, limit } }));
    },
    [setState],
  );

  return (
    <Row className="mb-2">
      <Col md={2} className="p-0">
        <select className="form-select" value={limit} onChange={onChange}>
          {[10, 20, 30, 40, 50].map((limit) => (
            <option key={['limit', limit].join('-')} value={limit}>
              {limit}개씩 보기
            </option>
          ))}
        </select>
      </Col>
    </Row>
  );
}
