import { v4 } from 'uuid';
import { useLocation } from 'react-router-dom';
import { clientStore } from '@/store';
import { sidebarItems } from './constants';
import { SidebarSingleItem } from './sidebar.single.item';
import { SidebarTreeItem } from './sidebar.tree.item';

const ids = { sidebar: 'sidebar-menu' };
const classes = { ul: 'metismenu list-unstyled', li: 'menu-title' };

export const SidebarMenuItems = () => {
  const location = useLocation();
  const policyFilter = clientStore.usePolicyFilter();

  return (
    <div id={ids.sidebar}>
      <ul id={ids.sidebar} className={classes.ul}>
        <li className={classes.li}>MEMU</li>
        {sidebarItems
          .filter(policyFilter)
          .map((item) =>
            item.branch.length ? (
              <SidebarTreeItem key={v4()} location={location} item={item} />
            ) : (
              <SidebarSingleItem key={v4()} location={location} item={item} />
            ),
          )}
      </ul>
    </div>
  );
};
