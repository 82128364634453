export enum ContentTemplateCode {
  NOTI = 'noti',
  SIMPLE = 'simple',
  WHEEL = 'wheel',
}

export enum DonationMessageType {
  NOTI = 'noti',
  SIMPLE = 'simple',
}

export enum DonationKeywordOption {
  DONATION_ID = 'donation-id',
  RECEIVER_ID = 'receiver-id',
  RECEIVER_NICKNAME = 'receiver-nickname',
  SENDER_ID = 'sender-id',
  SENDER_NICKNAME = 'sender-nickname',
  DONATION_NICKNAME = 'donation-nickname',
}

export enum DonationApiError {
  NotFoundDonationImage = 'NotFoundDonationImage',
}
