import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { PageHelmetProps } from './types';

export const PageHelmet: FC<PageHelmetProps> = ({ title }) => {
  const text = ['US - 스티키밤 관리자'];

  if (title) {
    text.push(title);
  }

  return (
    <Helmet>
      <title>{text.join(' | ')}</title>
    </Helmet>
  );
};
