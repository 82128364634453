import { ApiService } from '@/core/service';

export class WebhookApiService extends ApiService {
  async reportError(error: Error): Promise<void> {
    const appName = 'admin';

    return this.api.post(this.url('error'), {
      appName,
      errorName: error.name,
      errorMessage: error.message,
      errorStack: error.stack,
    });
  }
}

export const webhookApiService = new WebhookApiService('webhook');
