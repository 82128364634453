import { FC } from 'react';
import { sidebarAvatar } from '@/assets/images';

const classes = { div: 'logo-sm', img: 'avatar-xs mt-3' };

export const SidebarAvatarSmall: FC = () => {
  return (
    <div className={classes.div}>
      <img src={sidebarAvatar} className={classes.img} alt="img" />
    </div>
  );
};
