import { ApiExceptionType } from './types';

export class ApiException {
  protected readonly name: string;

  constructor(protected readonly e: ApiExceptionType) {
    this.name = e.error.name;
  }

  get message(): string {
    return this.name;
  }
}
