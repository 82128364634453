import { api } from '../instance';

export class ApiService {
  protected api = api;

  constructor(private readonly prefix: string = '/') {
    if (!prefix.startsWith('/')) {
      this.prefix = `/${this.prefix}`;
    }
  }

  protected url(...path: Array<string | number>): string {
    if (!path || !path.length) {
      return this.prefix;
    }

    return [this.prefix, ...path].join('/');
  }
}
