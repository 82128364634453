import { ApiService, ListApiService } from '@/core/service';
import { CreateRoleBodyDto, RoleDto, UpdateRoleBodyDto } from './types';
import { RoleApiException } from './role.exception';

export class RoleApiService extends ApiService implements ListApiService<RoleDto> {
  async getRows(): Promise<RoleDto[]> {
    return this.api.get(this.url());
  }

  async get(id: number): Promise<RoleDto> {
    return this.api.get(this.url(id));
  }

  async create(body: CreateRoleBodyDto): Promise<void> {
    return this.api.post(this.url(), body, {
      successAlert() {
        return '역할이 생성되었습니다.';
      },
      errorAlert(e) {
        return new RoleApiException(e).message;
      },
    });
  }

  async update(id: number, body: UpdateRoleBodyDto): Promise<void> {
    return this.api.patch(this.url(id), body, {
      successAlert() {
        return '역할이 수정되었습니다.';
      },
      errorAlert(e) {
        return new RoleApiException(e).message;
      },
    });
  }

  async delete(id: number): Promise<void> {
    return this.api.delete(this.url(id), {
      successAlert() {
        return '역할이 삭제되었습니다.';
      },
      errorAlert(e) {
        return new RoleApiException(e).message;
      },
    });
  }

  async addStaff(id: number, statffId: number): Promise<void> {
    return this.api.put(
      this.url(id, 'staff', statffId),
      {},
      {
        successAlert() {
          return '역할에 계정이 추가되었습니다.';
        },
        errorAlert(e) {
          return new RoleApiException(e).message;
        },
      },
    );
  }

  async removeStaff(id: number, staffId: number): Promise<void> {
    return this.api.delete(this.url(id, 'staff', staffId), {
      successAlert() {
        return '역할에 계정이 삭제되었습니다.';
      },
      errorAlert(e) {
        return new RoleApiException(e).message;
      },
    });
  }
}

export const roleApiService = new RoleApiService('roles');
