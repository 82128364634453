import { AwsS3Bucket, ISOToString, ListQueryStore, LuxonFormat, NumberToDollar, SortOrder, config } from '@/core';
import {
  PayoutApiService,
  PayoutDto,
  PayoutListQueryDto,
  PayoutAmountRangeOption,
  PayoutService,
  PayoutStatus,
  PayoutKeywordOption,
  PayoutPaypalStatus,
} from '@/service';
import { PayoutStoreValueType } from './types';
import { AccordionSelectOption, PaginationTableColumnType } from '@/components';
import { useCallback, useState } from 'react';
import { DateTime } from 'luxon';
import { CellProps } from 'react-table';

export class PayoutStore extends ListQueryStore<PayoutDto, PayoutStoreValueType, PayoutApiService> {
  private readonly payoutService = PayoutService.of();

  private static instance: PayoutStore;

  public static of(): PayoutStore {
    if (!this.instance) {
      const today = DateTime.local();
      const currentMonth = today.toFormat(LuxonFormat.Month);

      this.instance = new PayoutStore(
        {
          total: 0,
          totalGross: 0,
          totalStickybombFee: 0,
          totalNet: 0,
          rows: [],
          query: {
            keyword: '',
            offset: 0,
            limit: 10,
            sortOrder: SortOrder.DESC,
            startDate: currentMonth,
            endDate: currentMonth,
          },
        },
        PayoutApiService.of(),
      );
    }

    return this.instance;
  }

  useResetQuery(): () => void {
    const setState = this.useSetState();

    return useCallback(() => {
      setState((prev) => ({
        ...prev,
        query: {
          keyword: '',
          offset: prev.query.offset,
          limit: prev.query.limit,
          sortOrder: prev.query.sortOrder,
          startDate: prev.query.startDate,
          endDate: prev.query.endDate,
        },
      }));
    }, [setState]);
  }

  useSetRows(): () => Promise<void> {
    return super.useSetRows<PayoutListQueryDto>('offset', 'limit', 'startDate', 'endDate', 'sortOrder');
  }

  useSetRowsBySearch(): () => Promise<void> {
    return super.useSetRows<PayoutListQueryDto>();
  }

  useDisableDetail(): () => void {
    const setState = this.useSetState();

    return useCallback(() => {
      setState((prev) => ({ ...prev, detail: undefined }));
    }, [setState]);
  }

  useKeywordOptions(): AccordionSelectOption[] {
    return [
      {
        label: 'All',
        value: undefined,
      },
      {
        label: 'Payout ID',
        value: PayoutKeywordOption.ID,
      },
      {
        label: 'User ID',
        value: PayoutKeywordOption.USER_ID,
      },
      {
        label: 'Nickname',
        value: PayoutKeywordOption.NICKNAME,
      },
      {
        label: 'Paypal Email',
        value: PayoutKeywordOption.EMAIL,
      },
    ];
  }

  useAmountRangeOptions(): AccordionSelectOption[] {
    return [
      {
        label: 'All',
        value: undefined,
      },
      {
        label: 'Gross',
        value: PayoutAmountRangeOption.GROSS,
      },
      {
        label: 'Net',
        value: PayoutAmountRangeOption.NET,
      },
    ];
  }

  useStatusOptions(): AccordionSelectOption[] {
    return [
      {
        label: 'All',
        value: undefined,
      },
      {
        label: 'Waiting',
        value: PayoutStatus.WAITING,
      },
      {
        label: 'Processing',
        value: PayoutStatus.PROCESSING,
      },
      {
        label: 'Failed',
        value: PayoutStatus.FAILED,
      },
      {
        label: 'Completed',
        value: PayoutStatus.TRANSFERRED,
      },
    ];
  }

  usePaypalStatusOptions(): AccordionSelectOption[] {
    return [
      {
        label: PayoutPaypalStatus.PENDING,
        value: PayoutPaypalStatus.PENDING,
      },
      {
        label: PayoutPaypalStatus.PROCESSING,
        value: PayoutPaypalStatus.PROCESSING,
      },
      {
        label: PayoutPaypalStatus.ONHOLD,
        value: PayoutPaypalStatus.ONHOLD,
      },
      {
        label: PayoutPaypalStatus.SUCCESS,
        value: PayoutPaypalStatus.SUCCESS,
      },
      {
        label: PayoutPaypalStatus.UNCLAIMED,
        value: PayoutPaypalStatus.UNCLAIMED,
      },
      {
        label: PayoutPaypalStatus.CANCELED,
        value: PayoutPaypalStatus.CANCELED,
      },
      {
        label: PayoutPaypalStatus.FAILED,
        value: PayoutPaypalStatus.FAILED,
      },
      {
        label: PayoutPaypalStatus.BLOCKED,
        value: PayoutPaypalStatus.BLOCKED,
      },
      {
        label: PayoutPaypalStatus.REFUNDED,
        value: PayoutPaypalStatus.REFUNDED,
      },
      {
        label: PayoutPaypalStatus.REVERSED,
        value: PayoutPaypalStatus.REVERSED,
      },
      {
        label: PayoutPaypalStatus.RETURNED,
        value: PayoutPaypalStatus.RETURNED,
      },
    ];
  }

  useColumns(): PaginationTableColumnType<PayoutDto>[] {
    const setRows = this.useSetRowsBySearch();

    return [
      {
        accessor: 'id',
        Header: 'ID',
        minWidth: 40,
        maxWidth: 40,
        Cell: ({ value }) => (
          <div className="d-flex" style={{ height: 50, alignItems: 'center', justifyContent: 'center' }}>
            {value}
          </div>
        ),
      },
      {
        accessor: 'date',
        Header: 'Date',
        minWidth: 130,
        maxWidth: 130,
        Cell: ({ value }) => <>{DateTime.fromFormat(value.slice(0, 10), LuxonFormat.Day).toFormat(LuxonFormat.Day)}</>,
      },
      {
        id: 'receiver_id',
        Header: 'User ID',
        minWidth: 60,
        maxWidth: 60,
        Cell: ({ row }: CellProps<PayoutDto>) => <>{row.original.receiver.id}</>,
      },
      {
        accessor: 'receiver',
        Header: 'Profile',
        Cell: ({ value }) => (
          <div
            style={{
              display: 'flex',
              minWidth: 80,
              padding: '5px 0',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                height: 30,
                width: 30,
                margin: '0 10px 0 10px',
                borderRadius: '50%',
                background: `url(${config.S3_URL(AwsS3Bucket.Profile, value.img)})`,
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundPositionX: 'center',
              }}
            />
            <div>{value.nickname}</div>
          </div>
        ),
      },
      {
        id: 'payout_platform',
        Header: 'Platform',
        minWidth: 60,
        maxWidth: 60,
        Cell: ({ row }: CellProps<PayoutDto>) => {
          const data = row.original.receiver.platform ? row.original.receiver.platform : '-';
          return <>{data}</>;
        },
      },
      {
        id: 'payout_paypal_email',
        Header: 'Paypal Email',
        minWidth: 160,
        maxWidth: 160,
        Cell: ({ row }: CellProps<PayoutDto>) => {
          const data = row.original.receiver.email ? row.original.receiver.email : '-';
          return <>{data}</>;
        },
      },
      {
        accessor: 'donationAmount',
        Header: 'Donation',
        minWidth: 80,
        maxWidth: 80,
        Cell: ({ value }) => <>{NumberToDollar(value)}</>,
      },
      {
        accessor: 'stickerSaleAmount',
        Header: 'Sticker Sale',
        minWidth: 80,
        maxWidth: 80,
        Cell: ({ value }) => <>{NumberToDollar(value)}</>,
      },
      {
        accessor: 'gross',
        Header: 'Gross',
        minWidth: 80,
        maxWidth: 80,
        Cell: ({ value }) => <>{NumberToDollar(value)}</>,
      },
      {
        accessor: 'stickybombFee',
        Header: 'Service Fee',
        minWidth: 130,
        maxWidth: 130,
        Cell: ({ value }) => <>{NumberToDollar(value)}</>,
      },
      {
        accessor: 'net',
        Header: 'Net',
        minWidth: 80,
        maxWidth: 80,
        Cell: ({ value }) => <>{NumberToDollar(value)}</>,
      },
      {
        id: 'status',
        Header: 'Status',
        minWidth: 120,
        maxWidth: 120,
        Cell: ({ row }: CellProps<PayoutDto>) => {
          const id = row.original.id;
          const status = row.original.status;
          const canPayout = row.original.canPayout;

          const [className, setClassName] = useState<string>(
            this.payoutService.getClassNameByStatus(status, canPayout),
          );

          const [text, setText] = useState<string>(this.payoutService.getTextByStatus(status, canPayout));

          const onClick = useCallback(async () => {
            await this.service.payoutByPaypal(id);
            await setRows();
          }, [id, setRows]);

          const onButtonOver = useCallback(() => {
            setText(this.payoutService.getOverTextByStatus(PayoutStatus.TRANSFERRED));
            setClassName(this.payoutService.getOverClassNameByStatus(PayoutStatus.TRANSFERRED));
          }, [canPayout, setText, setClassName]);

          const onButtonLeave = useCallback(() => {
            setText(this.payoutService.getTextByStatus(status, canPayout));
            setClassName(this.payoutService.getClassNameByStatus(status, canPayout));
          }, [status, canPayout, setText, setClassName]);

          return (
            <button
              disabled={!canPayout}
              className={['btn', className].join(' ')}
              onClick={onClick}
              onMouseOver={onButtonOver}
              onMouseLeave={onButtonLeave}
            >
              {text}
            </button>
          );
        },
      },
      {
        id: 'directly',
        Header: 'directly',
        minWidth: 60,
        maxWidth: 60,
        Cell: ({ row }: CellProps<PayoutDto>) => {
          let text = '-';

          if (row.original.status === PayoutStatus.TRANSFERRED) {
            text = row.original.isDirectly ? 'O' : 'X';
          }

          return <>{text}</>;
        },
      },
      {
        accessor: 'transferredAt',
        Header: 'Transfer date',
        width: 140,
        minWidth: 140,
        maxWidth: 140,
        Cell: ({ value }) => <>{value ? ISOToString(value, LuxonFormat.Minute) : '-'}</>,
      },
    ];
  }
}
