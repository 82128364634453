import { Fragment } from 'react';
import { Table } from 'reactstrap';
import { useTable } from 'react-table';

import { PaginationTableProps } from './types';
import { PaginationTableHead } from './pagination-table.head';
import { PaginationTableBody } from './pagination-table.body';
import { PaginationTableLimitSelector } from './pagination-table.limit-selector';
import { PaginationTableOffsetSelector } from './pagination-table.offset-selector';
import { PaginationTableSortOrderSelector } from './pagination-table.sort-order-selector';

export function PaginationTable<D extends object>({
  columns,
  data,
  total,
  query,
  setState,
  emptyText,
}: PaginationTableProps<D>) {
  const { headerGroups, getTableProps, getTableBodyProps, rows, prepareRow } = useTable<D>({
    columns,
    data,
  });

  const tableProps = getTableProps({ style: { border: '1px solid #32394e' } });
  const tableBodyProps = getTableBodyProps();

  return (
    <Fragment>
      <PaginationTableSortOrderSelector sort={query.sortOrder} setQuery={setState} />
      <PaginationTableLimitSelector limit={query.limit} setState={setState} />

      <div className="table-responsive">
        <Table className="react-table" {...tableProps}>
          <PaginationTableHead headerGroups={headerGroups} />
          <PaginationTableBody
            columns={columns}
            rows={rows}
            prepareRow={prepareRow}
            tableBodyProps={tableBodyProps}
            emptyText={emptyText}
          />
        </Table>
      </div>
      <PaginationTableOffsetSelector total={total} limit={query.limit} offset={query.offset} setState={setState} />
    </Fragment>
  );
}
