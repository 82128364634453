import { FC, useCallback, useState } from 'react';
import { HeaderSidebarButton, HeaderClientButtons } from './buttons';
import { HeaderPasswordModal } from './modal';

const ids = { header: 'page-topbar' };
const classes = { div: 'navbar-header' };

export const LayoutHeader: FC = () => {
  const [open, setOpen] = useState<boolean>(false);

  const onToggle = useCallback(() => {
    setOpen((prev) => !prev);
  }, [setOpen]);

  return (
    <header id={ids.header}>
      <div className={classes.div}>
        <HeaderSidebarButton />
        <HeaderClientButtons onToggle={onToggle} />
      </div>
      <HeaderPasswordModal open={open} onToggle={onToggle} />
    </header>
  );
};
