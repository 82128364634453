import {
  atom,
  RecoilState,
  Resetter,
  SetterOrUpdater,
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';
import { v4 } from 'uuid';
import { ListDialogStoreValueType, ListQueryStoreValueType } from '@/core';
import { useCallback, useEffect } from 'react';
import { ApiService, ListApiService, ListQueryApiService } from '@/core';

export class Store<T, S = ApiService | undefined> {
  readonly state: RecoilState<T>;

  constructor(readonly init: T, readonly service: S, readonly key: string = v4()) {
    this.state = atom<T>({ key: this.key, default: this.init });
  }

  useValue(): T {
    return useRecoilValue(this.state);
  }

  useState(): [T, SetterOrUpdater<T>] {
    return useRecoilState(this.state);
  }

  useSetState(): SetterOrUpdater<T> {
    return useSetRecoilState(this.state);
  }

  useResetState(): Resetter {
    return useResetRecoilState(this.state);
  }
}

export class ListQueryStore<
  Row,
  StoreValue extends ListQueryStoreValueType<Row, any>,
  Service extends ListQueryApiService<Row>,
> extends Store<StoreValue, Service> {
  constructor(readonly init: StoreValue, readonly service: Service, readonly key: string = v4()) {
    super(init, service, key);
  }

  useSetRows<Q>(...keys: Array<keyof Q>): () => Promise<void> {
    const [{ query }, setState] = super.useState();

    return useCallback(
      async () => {
        const { results, total, amounts, ...others } = await this.service.getRowsAndStats(query);

        setState((prev) => ({ ...prev, rows: results, total, amounts, ...others }));
      },
      keys && keys.length > 0 ? keys.map((key) => query[key]) : [query],
    );
  }

  useInitRows(): void {
    const setRows = this.useSetRows();

    return useEffect(() => {
      setRows();
    }, [setRows]);
  }

  useCreateMode(): boolean {
    return super.useValue().createMode || false;
  }

  useUpdateTarget(): number | undefined {
    return super.useValue().updateId;
  }

  useDeleteTarget(): number | undefined {
    return super.useValue().deleteId;
  }

  useActiveCreateMode(): () => void {
    const setState = super.useSetState();

    return useCallback(() => {
      setState((prev) => ({ ...prev, createMode: true }));
    }, [setState]);
  }

  useDisableCreateMode(): () => void {
    const setState = super.useSetState();

    return useCallback(() => {
      setState((prev) => ({ ...prev, createMode: false }));
    }, [setState]);
  }

  useActiveUpdate(): (id: number) => void {
    const setState = this.useSetState();

    return useCallback(
      (updateId: number) => {
        setState((prev) => ({ ...prev, updateId }));
      },
      [setState],
    );
  }

  useDisableUpdate(): () => void {
    const setState = super.useSetState();

    return useCallback(() => {
      setState((prev) => ({ ...prev, updateId: undefined }));
    }, [setState]);
  }

  useActiveDelete(): (id: number) => void {
    const setState = this.useSetState();

    return useCallback(
      (deleteId: number) => {
        setState((prev) => ({ ...prev, deleteId }));
      },
      [setState],
    );
  }

  useDisableDelete(): () => void {
    const setState = super.useSetState();

    return useCallback(() => {
      setState((prev) => ({ ...prev, deleteId: undefined }));
    }, [setState]);
  }

  useActiveDetail(): (id: number) => void {
    const setState = this.useSetState();

    return useCallback(
      (detailId: number) => {
        setState((prev) => ({ ...prev, detailId }));
      },
      [setState],
    );
  }

  useDisableDetail(): () => void {
    const setState = super.useSetState();

    return useCallback(() => {
      setState((prev) => ({ ...prev, detailId: undefined }));
    }, [setState]);
  }
}

export class ListDialogStore<
  Row,
  StoreValue extends ListDialogStoreValueType<Row>,
  Service extends ListApiService<Row>,
> extends Store<StoreValue, Service> {
  constructor(readonly init: StoreValue, readonly service: Service, readonly key: string = v4()) {
    super(init, service, key);
  }

  useRows(): Row[] {
    return super.useValue().rows;
  }

  useCreateMode(): boolean {
    return super.useValue().createMode;
  }

  useUpdateTarget(): number | undefined {
    return super.useValue().updateId;
  }

  useDeleteTarget(): number | undefined {
    return super.useValue().deleteId;
  }

  useInitRows(): void {
    const setRows = this.useSetRows();

    return useEffect(() => {
      setRows();
    }, [setRows]);
  }

  useSetRows(): () => Promise<void> {
    const setState = super.useSetState();

    return useCallback(async () => {
      const rows = (await this.service.getRows()) || [];

      setState((prev) => ({ ...prev, rows: rows }));
    }, [setState]);
  }

  useActiveCreateMode(): () => void {
    const setState = super.useSetState();

    return useCallback(() => {
      setState((prev) => ({ ...prev, createMode: true }));
    }, [setState]);
  }

  useDisableCreateMode(): () => void {
    const setState = super.useSetState();

    return useCallback(() => {
      setState((prev) => ({ ...prev, createMode: false }));
    }, [setState]);
  }

  useActiveUpdate(): (id: number) => void {
    const setState = this.useSetState();

    return useCallback(
      (updateId: number) => {
        setState((prev) => ({ ...prev, updateId }));
      },
      [setState],
    );
  }

  useDisableUpdate(): () => void {
    const setState = super.useSetState();

    return useCallback(() => {
      setState((prev) => ({ ...prev, updateId: undefined }));
    }, [setState]);
  }

  useActiveDelete(): (id: number) => void {
    const setState = this.useSetState();

    return useCallback(
      (deleteId: number) => {
        setState((prev) => ({ ...prev, deleteId }));
      },
      [setState],
    );
  }

  useDisableDelete(): () => void {
    const setState = super.useSetState();

    return useCallback(() => {
      setState((prev) => ({ ...prev, deleteId: undefined }));
    }, [setState]);
  }

  useDisableDetail(): () => void {
    const setState = super.useSetState();

    return useCallback(() => {
      setState((prev) => ({ ...prev, detailId: undefined }));
    }, [setState]);
  }
}
