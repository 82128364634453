import { Button, Col, Input, Row } from 'reactstrap';
import { PaginationTableOffsetSelectorProps } from './types';
import { ChangeEventHandler, MouseEventHandler, useCallback, useState } from 'react';

export function PaginationTableOffsetSelector<D extends object>({
  total,
  limit,
  offset,
  setState,
}: PaginationTableOffsetSelectorProps<D>) {
  const max = Math.ceil(total / limit) - 1;

  const [page, setPage] = useState<string>((offset + 1).toString());

  const onClickPreEnd: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    setState((prev) => ({
      ...prev,
      query: { ...prev.query, offset: 0 },
    }));
  }, [setState]);

  const onClickPreOne: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    setState((prev) => ({
      ...prev,
      query: { ...prev.query, offset: prev.query.offset - 1 },
    }));
  }, [setState]);

  const onClickNextOne: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    setState((prev) => ({
      ...prev,
      query: { ...prev.query, offset: prev.query.offset + 1 },
    }));
  }, [setState]);

  const onClickNextEnd: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    setState((prev) => ({
      ...prev,
      query: { ...prev.query, offset: max },
    }));
  }, [max, setState]);

  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const { value } = e.target;

      if (value === '') {
        setPage('');
        return;
      }

      const page = Number(value);

      if (isNaN(page)) {
        return;
      }

      setPage(() => {
        if (page - 1 <= max) {
          setState((prev) => ({ ...prev, query: { ...prev.query, offset: page - 1 } }));
        }

        return value;
      });
    },
    [max, setPage],
  );

  return (
    <Row className="justify-content-md-end justify-content-center align-items-center">
      <Col className="col-md-auto">
        <div className="d-flex gap-1">
          <Button color="primary" onClick={onClickPreEnd} disabled={offset === 0}>
            {'<<'}
          </Button>
          <Button color="primary" onClick={onClickPreOne} disabled={offset === 0}>
            {'<'}
          </Button>
        </div>
      </Col>
      <Col className="col-md-auto d-none d-md-block">
        <strong>{offset + 1}</strong> / <strong>{max + 1 || 1}</strong>
      </Col>
      <Col className="col-md-auto">
        <Input style={{ width: 70 }} value={page} onChange={onChange} />
      </Col>
      <Col className="col-md-auto">
        <div className="d-flex gap-1">
          <Button color="primary" onClick={onClickNextOne} disabled={offset >= max}>
            {'>'}
          </Button>
          <Button color="primary" onClick={onClickNextEnd} disabled={offset >= max}>
            {'>>'}
          </Button>
        </div>
      </Col>
    </Row>
  );
}
