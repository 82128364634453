import { MouseEventHandler, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ListDialogStore, AwsS3Bucket, PagePath, config } from '@/core';
import {
  DragDropEndEventHandler,
  DragDropTableColumnType,
  DragDropTableRowClickEventHandler,
  OnChangeToggleSwitch,
  ToggleSwitch,
  getUpdateSequenceTargets,
  rootAlertStore,
} from '@/components';
import {
  CreateStickerCategoryBodyDto,
  StickerCategoryApiService,
  StickerCategoryDto,
  StickerCategoryStatus,
  UpdateStickerCategoryBodyDto,
  stickerCategoryApiService,
} from '@/service';

import { StickerCategoryStoreValueType, StickerPageParams } from './types';

export class StickerCategoryStore extends ListDialogStore<
  StickerCategoryDto,
  StickerCategoryStoreValueType,
  StickerCategoryApiService
> {
  private useColumnHandlers() {
    const setRows = this.useSetRows();
    const activeUpdate = this.useActiveUpdate();
    const activeDelete = this.useActiveDelete();

    const onChangeStatusHandler = useCallback(
      (row: StickerCategoryDto): OnChangeToggleSwitch =>
        async (checked) => {
          const status = checked ? StickerCategoryStatus.Active : StickerCategoryStatus.Disable;
          await this.service.updateStatus(row.id, status);
          await setRows();
        },
      [setRows],
    );

    const onChangeIsAdultHandler = useCallback(
      (row: StickerCategoryDto): OnChangeToggleSwitch =>
        async (checked) => {
          await this.service.updateIsAdult(row.id, checked);
          await setRows();
        },
      [setRows],
    );

    const onClickUpdateHandler = useCallback(
      (id: number): MouseEventHandler<HTMLButtonElement> => {
        return () => activeUpdate(id);
      },
      [activeUpdate],
    );

    const onClickDeleteHandler = useCallback(
      (id: number): MouseEventHandler<HTMLButtonElement> => {
        return () => activeDelete(id);
      },
      [activeDelete],
    );

    return { onChangeStatusHandler, onChangeIsAdultHandler, onClickUpdateHandler, onClickDeleteHandler };
  }

  useColumns(): DragDropTableColumnType<StickerCategoryDto>[] {
    const { onChangeStatusHandler, onChangeIsAdultHandler, onClickUpdateHandler, onClickDeleteHandler } = this.useColumnHandlers();

    return [
      {
        accessor: 'sequence',
        Header: '',
        minWidth: 20,
        maxWidth: 20,
        Cell: () => <i className="fa fa-fw fa-bars" />,
      },
      {
        accessor: 'id',
        Header: 'ID',
        minWidth: 40,
        maxWidth: 40,
      },
      {
        accessor: 'imagePath',
        Header: '이미지',
        minWidth: 80,
        maxWidth: 80,
        Cell: ({ value }) => (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div
              className="avatar-md rounded bg-light"
              style={{
                background: `url(${value && config.S3_URL(AwsS3Bucket.StickerCategory, value)})`,
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundPositionX: 'center',
              }}
            />
          </div>
        ),
      },
      {
        accessor: 'name',
        Header: '이름',
        minWidth: 120,
        maxWidth: 120,
      },
      {
        accessor: 'status',
        Header: '공개/비공개',
        minWidth: 80,
        maxWidth: 80,
        Cell: ({ row }) => {
          return (
            <ToggleSwitch
              width={36}
              height={18}
              checked={row.original.status === StickerCategoryStatus.Active}
              onChange={onChangeStatusHandler(row.original)}
            />
          );
        },
      },
      {
        accessor: 'isAdultSticker',
        Header: '18+',
        minWidth: 80,
        maxWidth: 80,
        Cell: ({ row }) => {
          return (
            <ToggleSwitch
              width={36}
              height={18}
              checked={row.original.isAdultSticker}
              onChange={onChangeIsAdultHandler(row.original)}
            />
          );
        },
      },
      {
        id: 'actions',
        minWidth: 100,
        maxWidth: 100,
        Cell: ({ row }: { row: { original: StickerCategoryDto } }) => (
          <div>
            <button onClick={onClickUpdateHandler(row.original.id)}>
              <i className="bx fs-4 bx-pencil custom-icon" />
            </button>
            <button onClick={onClickDeleteHandler(row.original.id)}>
              <i className="bx fs-4 bx-trash text-danger custom-icon" />
            </button>
          </div>
        ),
      },
    ];
  }

  useCurrent(): number | undefined {
    return this.useValue().currentId;
  }

  useCurrentCategory(): StickerCategoryDto | undefined {
    return this.useValue().currentCategory;
  }

  useInitCurrent() {
    const params = useParams<StickerPageParams>();
    const navigate = useNavigate();

    const [{ currentId }, setState] = this.useState();

    useEffect(() => {
      if (!params.categoryId) {
        setState((prev) => ({ ...prev, currentId: undefined, currentCategory: undefined }));
        return;
      }

      const categoryId = parseInt(params.categoryId);

      if (isNaN(categoryId)) {
        return;
      }

      this.service
        .has(categoryId)
        .then(() =>
          setState((prev) => ({
            ...prev,
            currentId: categoryId,
            currentCategory: prev.rows.find(({ id }) => id === categoryId),
          })),
        )
        .catch(() =>
          navigate(currentId ? [PagePath.AssetSticker, currentId].join('/') : PagePath.AssetSticker, { replace: true }),
        );
    }, [params, navigate, currentId, setState]);
  }

  useOnClickRowHandler(): DragDropTableRowClickEventHandler<StickerCategoryDto> {
    const navigate = useNavigate();

    return useCallback(
      (row) => {
        return () => navigate([PagePath.AssetSticker, row.id].join('/'));
      },
      [navigate],
    );
  }

  useOnClickCreateHandler(body: CreateStickerCategoryBodyDto): MouseEventHandler {
    const setAlert = rootAlertStore.useSetState();
    const setRows = this.useSetRows();
    const disableCreateMode = this.useDisableCreateMode();

    return useCallback(async () => {
      let message = '';

      if (!body.name) {
        message = '이름을 입력하세요.';
      } else if (!body.image) {
        message = '이미지를 등록하세요';
      }

      if (message) {
        setAlert({ type: 'danger', message });
        return;
      }

      await this.service.create(body);
      await setRows();

      disableCreateMode();
    }, [body, setAlert, setRows, disableCreateMode]);
  }

  useOnClickUpdateHandler(id: number | undefined, body: UpdateStickerCategoryBodyDto): () => Promise<void> {
    const setAlert = rootAlertStore.useSetState();
    const setRows = this.useSetRows();
    const disableUpdate = this.useDisableUpdate();

    return useCallback(async () => {
      if (!id) {
        return;
      }

      let message = '';

      if (!body.name) {
        message = '이름을 입력하세요.';
      }

      if (message) {
        setAlert({ type: 'danger', message });
        return;
      }

      await this.service.update(id, body);
      await setRows();

      disableUpdate();
    }, [id, body, setAlert, setRows, disableUpdate]);
  }

  useOnClickDeleteHandler(id?: number): () => Promise<void> {
    const setRows = this.useSetRows();
    const disableDelete = this.useDisableDelete();

    return useCallback(async () => {
      if (!id) {
        return;
      }

      await this.service.delete(id);
      await setRows();

      disableDelete();
    }, [id, setRows, disableDelete]);
  }

  useOnUpdateSequence(): DragDropEndEventHandler {
    const rows = this.useRows();
    const setRows = this.useSetRows();

    return useCallback(
      async (startIndex, endIndex) => {
        const targets = getUpdateSequenceTargets(rows, startIndex, endIndex);

        if (targets.length === 0) {
          return;
        }

        await this.service.updateSequence(targets);
        await setRows();
      },
      [rows, setRows],
    );
  }
}

export const stickerCategoryStore = new StickerCategoryStore(
  {
    currentId: undefined,
    rows: [],
    createMode: false,
    updateId: undefined,
    deleteId: undefined,
  },
  stickerCategoryApiService,
);
