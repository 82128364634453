import { ApiException } from '@/core';
import { StickerApiError } from './enums';

export class StickerApiException extends ApiException {
  get message(): string {
    let message = this.name;

    switch (this.name) {
      case StickerApiError.NotFoundStickerCategory:
        message = '스티커 카테고리를 찾을 수 없습니다.';
        break;

      case StickerApiError.NotFoundSticker:
        message = '스티커를 찾을 수 없습니다.';
        break;
    }

    return message;
  }
}
