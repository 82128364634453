import { FC, useCallback } from 'react';

const classes = {
  body: {
    wide: 'vertical-collpsed',
    narrow: 'sidebar-enable',
  },
  div: 'd-flex',
  button: 'btn btn-sm px-3 font-size-16 header-item',
  icon: 'fa fa-fw fa-bars',
};

export const HeaderSidebarButton: FC = () => {
  const onClick = useCallback(() => {
    const body = document.body;

    if (window.screen.width > 998) {
      body.classList.toggle(classes.body.wide);
    }

    body.classList.toggle(classes.body.narrow);
  }, []);

  return (
    <div className={classes.div}>
      <button onClick={onClick} className={classes.button}>
        <i className={classes.icon} />
      </button>
    </div>
  );
};
