import { FC } from 'react';
import DateTimeRangePicker, { DateRangePickerProps } from '@wojtekmaj/react-daterange-picker';

import './date-picker.scss';

export const DatePicker: FC<DateRangePickerProps> = (props) => {
  return (
    <div className="position-absolute text-center">
      <DateTimeRangePicker {...props} yearPlaceholder="년" monthPlaceholder="월" dayPlaceholder="일" />
    </div>
  );
};
