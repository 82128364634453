import { ApiService, ListApiService } from '@/core/service';
import { MaintenanceDto, CreateMaintenanceBodyDto, UpdateMaintenanceBodyDto } from './types';
import { MaintenanceStatus } from './enums';
import { MaintenanceApiException } from './maintenance.exception';
import { ApiAlertConfig, ApiHandlerConfig } from '@/core';

export class MaintenanceApiService extends ApiService implements ListApiService<MaintenanceDto> {
  async getRows(): Promise<MaintenanceDto[]> {
    return this.api.get(this.url());
  }

  async getRow(id: number): Promise<MaintenanceDto> {
    return this.api.get(this.url(id), {
      errorAlert(e) {
        return new MaintenanceApiException(e).message;
      },
    });
  }

  async create(body: CreateMaintenanceBodyDto) {
    const alertConfig: ApiAlertConfig = {
      successAlert() {
        return '점검 항목이 생성되었습니다.';
      },
      errorAlert(e) {
        return new MaintenanceApiException(e).message;
      },
    };

    return this.api.post(this.url(), body, { ...alertConfig });
  }

  async update(id: number, body: UpdateMaintenanceBodyDto) {
    return this.api.patch(this.url(id), body, {
      successAlert() {
        return '점검 내용이 수정되었습니다.';
      },
      errorAlert(e) {
        return new MaintenanceApiException(e).message;
      },
    });
  }

  async updateStatus(id: number, status: MaintenanceStatus, handlerConfig: ApiHandlerConfig) {
    const alertConfig: ApiAlertConfig = {
      successAlert() {
        const how = status === MaintenanceStatus.Start ? '시작' : '종료';
        return ['점검이', how, '되었습니다.'].join(' ');
      },
      errorAlert(e) {
        return new MaintenanceApiException(e).message;
      },
    };

    return this.api.patch(this.url(id, 'status'), { status }, { ...alertConfig, ...handlerConfig });
  }

  async delete(id: number, handlerConfig: ApiHandlerConfig): Promise<void> {
    const alertConfig: ApiAlertConfig = {
      successAlert() {
        return '점검 항목이 삭제되었습니다.';
      },
      errorAlert(e) {
        return new MaintenanceApiException(e).message;
      },
    };

    return this.api.delete(this.url(id), { ...alertConfig, ...handlerConfig });
  }
}

export const maintenanceApiService = new MaintenanceApiService('maintenances');
