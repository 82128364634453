import { ContentModalTextAreaProps } from './types';

export function ContentModalTextArea({ label, name, style, ...props }: ContentModalTextAreaProps) {
  return (
    <div className="form-group mb-4">
      {label && <label>{label}</label>}
      <textarea
        {...props}
        name={name}
        className="form-control"
        autoComplete="off"
        style={{
          color: '#fff',
          resize: 'none',
          ...style,
        }}
      />
    </div>
  );
}
