import { FC, MouseEvent, useCallback, useState } from 'react';
import { v4 } from 'uuid';
import { Link } from 'react-router-dom';
import { SidebarTreeItemProps } from './types';
import { SidebarSingleItem } from './sidebar.single.item';

export const SidebarTreeItem: FC<SidebarTreeItemProps> = ({ location, item }) => {
  const { path, icon, title, branch } = item;

  const active = location.pathname.startsWith(path) ? 'mm-active' : '';
  const [spread, setSpread] = useState<boolean>(!!active);

  const classes = {
    link: spread ? '' : 'mm-collapsed',
    ul: spread ? '' : 'mm-collapse',
  };

  const onClick = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      setSpread((prev) => !prev);
    },
    [setSpread],
  );

  return (
    <li className={active}>
      <Link to="#" onClick={onClick} className={classes.link} aria-expanded={active ? 'true' : 'false'}>
        <i className={icon}></i>
        <span>{title}</span>
      </Link>
      <ul className={classes.ul}>
        {branch.map((item) => (
          <SidebarSingleItem key={v4()} location={location} item={item} />
        ))}
      </ul>
    </li>
  );
};
