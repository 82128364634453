import { ApiService } from '@/core/service';
import {
  CreateTtsVoiceBodyDto,
  CreateTtsVoiceSampleBodyDto,
  TtsVoiceDto,
  TtsVoiceSampleDto,
  TtsVoiceSequenceRowDto,
  UpdateTtsVoiceBodyDto,
} from './types';
import { TtsStatus } from './enums';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { debounce } from 'lodash';
import { TtsVoiceApiException } from './tts-voice.exception';

export class TtsVoiceApiService extends ApiService {
  async getRows(): Promise<TtsVoiceDto[]> {
    return this.api.get(this.url());
  }

  async get(id: number): Promise<TtsVoiceDto> {
    return this.api.get(this.url(id));
  }

  async create(body: CreateTtsVoiceBodyDto): Promise<void> {
    return this.api.post(this.url(), body, {
      successAlert() {
        return 'TTS가 등록되었습니다.';
      },
      errorAlert(e) {
        return new TtsVoiceApiException(e).message;
      },
    });
  }

  async update(id: number, body: UpdateTtsVoiceBodyDto): Promise<void> {
    return this.api.patch(this.url(id), body, {
      successAlert() {
        return 'TTS 정보가 수정되었습니다.';
      },
      errorAlert(e) {
        return new TtsVoiceApiException(e).message;
      },
    });
  }

  async updateIsNew(id: number, isNew: boolean): Promise<void> {
    return this.api.patch(
      this.url(id),
      { isNew },
      {
        successAlert() {
          return 'TTS 신상 여부가 변경되었습니다.';
        },
        errorAlert(e) {
          return new TtsVoiceApiException(e).message;
        },
      },
    );
  }

  async updateStatus(id: number, status: TtsStatus): Promise<void> {
    return this.api.patch(
      this.url(id),
      { status },
      {
        successAlert() {
          return 'TTS 상태가 변경되었습니다.';
        },
        errorAlert(e) {
          return new TtsVoiceApiException(e).message;
        },
      },
    );
  }

  async updateSequence(rows: TtsVoiceSequenceRowDto[]): Promise<void> {
    return this.api.patch(
      this.url('sequence'),
      { rows },
      {
        successAlert() {
          return 'TTS 순서가 변경되었습니다.';
        },
        errorAlert(e) {
          return new TtsVoiceApiException(e).message;
        },
      },
    );
  }

  async delete(id: number): Promise<void> {
    return this.api.delete(this.url(id), {
      successAlert() {
        return 'TTS가 삭제되었습니다.';
      },
      errorAlert(e) {
        return new TtsVoiceApiException(e).message;
      },
    });
  }

  async makeSample(body: CreateTtsVoiceSampleBodyDto): Promise<TtsVoiceSampleDto> {
    return this.api.post(this.url('sample'), body);
  }

  useMakeTtsSampleDebounce(setSrc: Dispatch<SetStateAction<string | undefined>>) {
    return useCallback(
      debounce(async (body: CreateTtsVoiceSampleBodyDto) => {
        const { provider, language, code, sampleText } = body;

        if (provider && language && code && sampleText) {
          const { url } = await this.makeSample({ provider, language, code, sampleText });

          setSrc(url);
        }
      }, 1000),
      [setSrc],
    );
  }
}

export const ttsVoiceApiService = new TtsVoiceApiService('tts-voices');
