import { FC, Fragment, useCallback, useEffect, useState } from 'react';
import { Progress, UncontrolledAlert } from 'reactstrap';
import { rootAlertStore } from './root.alert.store';

const classes = {
  alert: 'alert-dismissible fade show position-fixed display-flex',
  div: 'animated-progess mt-2',
  icon: {
    info: 'mdi mdi-check-bold',
    warning: 'mdi mdi-block-helper',
    danger: 'mdi mdi-block-helper',
  },
};

export const RootAlert: FC = () => {
  const [state, setState] = rootAlertStore.useState();
  const [open, setOpen] = useState<boolean>(!!state);

  const onToggle = useCallback(() => {
    setOpen(false);
    setTimeout(() => setState(undefined), 200);
  }, [setOpen, setState]);

  useEffect(() => {
    setOpen(!!state);
  }, [state, setOpen]);

  useEffect(() => {
    if (open) {
      const timeouts = [setTimeout(() => setOpen(false), 3000), setTimeout(() => setState(undefined), 3200)];

      return () => {
        if (Array.isArray(timeouts)) {
          timeouts.forEach(clearTimeout);
        }
      };
    }
  }, [open, setOpen, setState]);

  if (!state) {
    return <Fragment />;
  }

  return (
    <UncontrolledAlert
      color={state.type}
      isOpen={open}
      toggle={onToggle}
      className={classes.alert}
      millisecond={2500}
      style={{ width: 360, right: '4%', top: '4%', zIndex: 99999 }}
    >
      <i className={classes.icon[state.type]} />
      <span className="ms-2">{state?.message}</span>
      <div className={classes.div}>
        <Progress value={100} color={state.type} />
      </div>
    </UncontrolledAlert>
  );
};
