import { ApiException } from '@/core';
import { PayoutApiError } from './enums';

export class PayoutApiException extends ApiException {
  get message(): string {
    let text = this.name;

    switch (this.name) {
      case PayoutApiError.NotExistCanPayouts:
        text = '정산 가능한 항목이 없습니다.';
        break;

      case PayoutApiError.CannotPayoutDate:
        text = '정산 가능 일자가 아닙니다.';
        break;

      case PayoutApiError.CannotPayoutStatus:
        text = '정산 가능한 상태가 아닙니다.';
        break;

      case PayoutApiError.NotFoundPayout:
        text = '정산 항목을 찾을 수 없습니다.';
        break;

      case PayoutApiError.NotFoundPaypalAccount:
        text = 'Paypal 계정이 존재하지 않습니다.';
        break;
    }

    return text;
  }
}
