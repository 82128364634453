import { ApiService, ListQueryApiService } from '@/core';
import { PayoutDto, PayoutListDto, PayoutListQueryDto } from './types';
import { PayoutApiException } from './payout-api.exception';

export class PayoutApiService extends ApiService implements ListQueryApiService<PayoutDto> {
  private static instance: PayoutApiService;

  public static of(): PayoutApiService {
    if (!this.instance) {
      this.instance = new PayoutApiService('payouts');
    }

    return this.instance;
  }

  async getRowsAndStats(params: PayoutListQueryDto): Promise<PayoutListDto> {
    return this.api.get(this.url(), { params });
  }

  async payoutByPaypal(id: number): Promise<void> {
    return this.api.patch(
      this.url(id),
      {},
      {
        successAlert() {
          return '정산 완료 처리되었습니다.';
        },
        errorAlert(e) {
          return new PayoutApiException(e).message;
        },
      },
    );
  }
}
