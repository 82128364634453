import { ApiException } from '@/core';
import { StickiesApiError } from './enums';

export class StickiesApiException extends ApiException {
  get message(): string {
    let message = this.name;

    switch (this.name) {
      case StickiesApiError.INVALID_GROSS_AMOUNT:
        message = '충전 금액은 0보다 큰 값이어야 합니다.';
        break;

      case StickiesApiError.INVALID_DISCOUNT_AMOUNT:
        message = '할인율은 100%를 넘을 수 없습니다.';
        break;

      case StickiesApiError.ALREADY_EXIST_ITEM:
        message = '이미 충전 금액과 같은 항목이 존재합니다.';
        break;
    }

    return message;
  }
}
