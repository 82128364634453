import { FC, useCallback, useState } from 'react';
import { Row } from 'reactstrap';
import { HeaderPasswordModalInputProps } from './types';

const classes = {
  row: 'mb-3 justify-content-center',
  div: 'col-md-9 w-100',
  input: 'form-control',
  icon: {
    show: 'font-size-16 align-middle me-1 mdi mdi-eye',
    hidden: 'font-size-16 align-middle me-1 mdi mdi-eye-off',
  },
};

export const HeaderPasswordModalInput: FC<HeaderPasswordModalInputProps> = ({ labelText, error, ...props }) => {
  const [visible, setVisible] = useState<boolean>(false);

  const onClick = useCallback(() => {
    setVisible((prev) => !prev);
  }, [setVisible]);

  return (
    <Row className={classes.row}>
      <div className={classes.div}>
        <input className={classes.input} type={visible ? 'text' : 'password'} autoComplete="off" {...props} />
        <button onClick={onClick} style={{ position: 'absolute', top: 6, right: 20 }}>
          <i className={classes.icon[visible ? 'show' : 'hidden']} />
        </button>
      </div>
    </Row>
  );
};
