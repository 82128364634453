import { PaginationTableBodyProps } from './types';

export function PaginationTableBody<D extends object>({
  rows,
  columns,
  tableBodyProps,
  emptyText,
  prepareRow,
}: PaginationTableBodyProps<D>) {
  return (
    <tbody {...tableBodyProps}>
      {rows.length === 0 ? (
        <tr>
          <td align="center" colSpan={columns.length}>
            {emptyText}
          </td>
        </tr>
      ) : (
        rows.map((row) => {
          prepareRow(row);

          const id = ['pagination', 'row', row.id].join('-');
          const rowProps = row.getRowProps();

          return (
            <tr {...rowProps} id={id}>
              {row.cells.map((cell) => {
                const cellProps = cell.getCellProps({
                  className: 'align-middle py-1 px-2 text-center',
                });

                return <td {...cellProps}>{cell.render('Cell')}</td>;
              })}
            </tr>
          );
        })
      )}
    </tbody>
  );
}
