import { ApiException } from '@/core';
import { MaintenanceApiError } from './enums';

export class MaintenanceApiException extends ApiException {
  get message(): string {
    let message = this.name;

    switch (this.name) {
      case MaintenanceApiError.NotFoundMaintenance:
        message = '점검 항목을 찾을 수 없습니다.';
        break;

      case MaintenanceApiError.AlreadyStartMaintenance:
        message = '이미 점검 진행 중입니다.';
        break;

      case MaintenanceApiError.CannotUpdateMaintenance:
        message = '진행 중인 내용은 수정할 수 없습니다.';
        break;

      case MaintenanceApiError.CannotDeleteMaintenance:
        message = '진행 중인 점검 항목은 삭제할 수 없습니다.';
        break;
    }

    return message;
  }
}
