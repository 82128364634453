import { FC, useRef } from 'react';
import SimpleBar from 'simplebar-react';
import SimpleBarCore from 'simplebar-core';
import { SidebarMenuItems } from './items';

const classes = { simplebar: 'h-100', ul: 'metismenu list-unstyled' };

export const SidebarMenu: FC = () => {
  const ref = useRef<SimpleBarCore | null>(null);

  return (
    <SimpleBar ref={ref} className={classes.simplebar}>
      <SidebarMenuItems />
    </SimpleBar>
  );
};
