import { FC } from 'react';
import { v4 } from 'uuid';
import { sidebarAvatar } from '@/assets/images';
import { clientStore } from '@/store';

const classes = {
  div: 'logo-lg',
  img: 'avatar-md mb-3',
  section: 'text-start lh-base',
};

export const SidebarAvatarLarge: FC = () => {
  const client = clientStore.useValue();

  return (
    <div className={classes.div}>
      <img src={sidebarAvatar} className={classes.img} alt="img" />
      <section className={classes.section}>
        <b>{client?.name}</b>님 어서오세요!
        <br />
        <div>
          {client?.roles.map((role) => (
            <b key={v4()}>@{role.name}</b>
          ))}
        </div>
      </section>
    </div>
  );
};
