import { FC, Fragment } from 'react';
import { clientStore } from '@/store';
import { SidebarAvatar } from './avatar';
import { SidebarMenu } from './menu';

const classes = { aside: 'vertical-menu', div: 'simple-bar sidebar-background' };

export const LayoutSidebar: FC = () => {
  const client = clientStore.useValue();

  if (!client) {
    return <Fragment />;
  }

  return (
    <aside className={classes.aside}>
      <SidebarAvatar />
      <div data-simplebar className={classes.div}>
        <SidebarMenu />
      </div>
    </aside>
  );
};
