import { Component, PropsWithChildren } from 'react';
import { ErrorBoundaryStateValueType } from './types';
import { ErrorComponent } from './error.component';

export class ErrorBoundary extends Component {
  readonly state: ErrorBoundaryStateValueType;

  constructor(readonly props: PropsWithChildren) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
    };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  render() {
    if (this.state.hasError && this.state.error) {
      return <ErrorComponent error={this.state.error} />;
    }

    return this.props.children;
  }
}
