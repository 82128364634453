import { FC } from 'react';
import { ContentModalProps } from './types';
import { Modal, ModalBody } from 'reactstrap';

export const ContentModal: FC<ContentModalProps> = ({ open, title, onClose, children, ...props }) => {
  return (
    <Modal isOpen={open} toggle={onClose} role="dialog" autoFocus={true} centered data-toggle="modal" {...props}>
      <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">
          {title}
        </h5>
        <button type="button" className="btn-close" aria-label="Close" onClick={onClose} />
      </div>
      <ModalBody>{children}</ModalBody>
    </Modal>
  );
};
